div.alumni {
  height: auto;
}

h1.alumniTopHead {
  display: flex;
  margin: 63px auto auto auto;
  width: 120px;
  height: 38px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 64px;
  border: 1px solid #8ac3ff;
  background: rgba(231, 243, 255, 0.5);
}

h1.alumniTopHead > span {
  color: #0c0c0c;
  text-align: center;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
}

.alumniTopP {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 487px;
  height: 100px;
  justify-content: center;
  margin: 32px auto 18px auto;
}

.alumniTopP > p {
  color: var(--Text-Primary, #121212);
  text-align: center;
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.48px;
}

.alumniTopP > p > span {
  color: #eb460e;
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.48px;
}

.alumniTop p.sentence {
  display: flex;
  margin: 0px auto;

  width: 876px;
  height: 48px;
  color: var(--Text-Secondary, #7a7a7a);
  text-align: center;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
}

.alumniImages {
  display: flex;
  flex-direction: row;
  gap: 0;
  width: 100vw;
  height: 742px;
  align-items: flex-start;
  flex-shrink: 0;
  overflow: hidden;
  border: 1px solid #fff;
  border-top: 0;
  position: relative;
}

.alumniImages > * {
  flex: 1;
  height: 742px;
}

.alumniImages > * > img {
  filter: grayscale(100);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.person1:hover > img,
.person2:hover > img,
.person3:hover > img,
.person4:hover > img {
  filter: grayscale(0);
  scale: 1.5;
  transition: all 3s;
}

.alumniImages::before {
  content: "";
  position: absolute;
  height: 300px;
  left: -20%;
  right: -20%;
  border-radius: 50%;
  top: -250px;
  background-color: #fff;
  z-index: 2;
}

.alumniImages::after {
  content: "";
  position: absolute;
  height: 300px;
  left: -20%;
  right: -20%;
  border-radius: 50%;
  top: -260px;
  z-index: 1;
}

.alumniAd {
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.alumniAdTop {
  display: flex;
  width: 503px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.alumniAdTop p {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  text-align: center;
  font-family: "Manrope";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.36px;
}

.alumniAdTop p > span {
  color: var(--Text-Primary, #eb460e);
  font-family: "Manrope";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.36px;
}

.alumniAdStatement {
  width: 876px;
  height: 48px;
  color: var(--Text-Secondary, #7a7a7a);
  text-align: center;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
}

.alumniAd > button {
  display: flex;
  gap: 15px;
  padding: 20px 40px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  background: #004a98;
  border: none;
  width: 223.05px;
  height: 59px;
}

.alumniAd > button > span {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.iconAlumni {
  color: #fff;
}

section {
  display: flex;
  height: 715px;
  padding: 24px 120px;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.videoTestimonials {
  width: 1200px;
  height: 667px;
  display: flex;
  flex-direction: row;
  gap: 22px;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--Surface, #f7f7f7);
}

.videoTestimonials > .videos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  width: 878px;
  height: 635px;
  flex-wrap: wrap;
  border-radius: 16px;
}

.videos > :first-child {
  width: 100%; /* Inherit the width from the parent grid container */
  height: 201px;
  border-radius: 16px;
}

.videos > :nth-child(2) {
  width: 48%; /* Set width to 100% to fill the available space */
  height: 201px;
  border-radius: 16px;
}

.videos > :nth-child(3) {
  width: 48%;
  height: 201px;
  border-radius: 16px;
}

.videos > :nth-child(4) {
  width: 48%;
  height: 201px;
  border-radius: 16px;
}

.videos > :nth-child(5) {
  width: 48%;
  height: 201px;
  border-radius: 16px;
}

.alumniNames {
  display: flex;
  width: 242px;
  padding: 106px 16px 153px 16px;
  flex-direction: column;
  align-items: center;
}

.alumniDetails h1 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 240px;
  gap: 24px;
  padding: 12px 30px;
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.24px;
}

.alumniNames ul {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media only screen and (max-width: 600px) {
  div.alumni {
    height: auto;
  }

  div.alumniTop {
    /* width: 100%;*/
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h1.alumniTopHead {
    display: flex;
    margin: auto;
    width: 80px;
    height: 38px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 64px;
    border: 1px solid #8ac3ff;
    background: rgba(231, 243, 255, 0.5);
  }

  h1.alumniTopHead > span {
    color: #0c0c0c;
    text-align: center;
    font-family: "Manrope";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.16px;
  }

  .alumniTopP {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 200px;
    height: auto;
    justify-content: center;
    margin: 32px auto 18px auto;
  }

  .alumniTopP > p {
    color: var(--Text-Primary, #121212);
    text-align: center;
    font-family: Manrope;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.48px;
  }

  .alumniTopP > p > span {
    color: #eb460e;
    font-family: "Manrope", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.48px;
  }

  .alumniTop p.sentence {
    display: flex;
    margin: 0px auto;
    width: 876px;
    height: 148px;
    max-width: fit-content;
    color: var(--Text-Secondary, #7a7a7a);
    text-align: center;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
  }

  .alumniImages {
    display: flex;
    flex-direction: row;
    gap: 0;
    width: 100vw;
    height: 742px;
    align-items: flex-start;
    flex-shrink: 0;
    overflow: hidden;
    border: 1px solid #fff;
    border-top: 0;
    position: relative;
  }

  .alumniImages > * {
    flex: 1;
    height: 742px;
  }

  .alumniImages > * > img {
    filter: grayscale(100);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .person1:hover > img,
  .person2:hover > img,
  .person3:hover > img,
  .person4:hover > img {
    filter: grayscale(0);
    scale: 1.5;
    transition: all 3s;
  }

  .alumniImages::before {
    content: "";
    position: absolute;
    height: 300px;
    left: -20%;
    right: -20%;
    border-radius: 50%;
    top: -250px;
    background-color: #fff;
    z-index: 2;
  }

  .alumniImages::after {
    content: "";
    position: absolute;
    height: 300px;
    left: -20%;
    right: -20%;
    border-radius: 50%;
    top: -260px;
    z-index: 1;
  }

  .alumniAd {
    display: flex;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }

  .alumniAdTop {
    display: flex;
    width: 503px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .alumniAdTop p {
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    text-align: center;
    font-family: "Manrope";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.36px;
  }

  .alumniAdTop p > span {
    color: var(--Text-Primary, #eb460e);
    font-family: "Manrope";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.36px;
  }

  .alumniAdStatement {
    width: 876px;
    height: 48px;
    color: var(--Text-Secondary, #7a7a7a);
    text-align: center;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
  }

  .alumniAd > button {
    display: flex;
    gap: 15px;
    margin-top: 50px;
    padding: 20px 40px;
    justify-content: center;
    align-items: flex-start;
    border-radius: 5px;
    background: #004a98;
    border: none;
    width: 223.05px;
    height: 59px;
  }

  .alumniAd > button > span {
    color: #fff;
    text-align: center;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .iconAlumni {
    color: #fff;
  }

  section {
    display: flex;
    height: 715px;
    padding: 24px 120px;
    justify-content: center;
    align-items: center;
    background: #fff;
  }

  .videoTestimonials {
    width: 1200px;
    height: 667px;
    display: flex;
    flex-direction: row;
    gap: 22px;
    flex-shrink: 0;
    border-radius: 16px;
    background: var(--Surface, #f7f7f7);
  }

  .videoTestimonials > .videos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    width: 878px;
    height: 635px;
    flex-wrap: wrap;
    border-radius: 16px;
  }

  .videos > :first-child {
    width: 100%; /* Inherit the width from the parent grid container */
    height: 201px;
    border-radius: 16px;
  }

  .videos > :nth-child(2) {
    width: 48%; /* Set width to 100% to fill the available space */
    height: 201px;
    border-radius: 16px;
  }

  .videos > :nth-child(3) {
    width: 48%;
    height: 201px;
    border-radius: 16px;
  }

  .videos > :nth-child(4) {
    width: 48%;
    height: 201px;
    border-radius: 16px;
  }

  .videos > :nth-child(5) {
    width: 48%;
    height: 201px;
    border-radius: 16px;
  }

  .alumniNames {
    display: flex;
    width: 242px;
    padding: 106px 16px 153px 16px;
    flex-direction: column;
    align-items: center;
  }

  .alumniDetails h1 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 240px;
    gap: 24px;
    padding: 12px 30px;
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  .alumniNames ul {
    display: flex;
    flex-direction: column;
    gap: 14px;
    text-align: left;
    justify-items: left;
    margin-left: -11em;
  }

  /* Mobile Styles */
  @media (max-width: 768px) {
    h1.alumniTopHead {
      margin: 32px auto auto auto;
      width: auto;
      height: auto;
      padding: 8px 12px;
    }

    .alumniTopP > p {
      font-size: 24px;
      line-height: 28px;
    }

    .alumniTop p.sentence {
      width: 100%;
      padding: 0 16px;
      font-size: 14px;
    }

    .alumniImages {
      height: auto;
      flex-direction: column;
    }

    .alumniImages > * {
      height: auto;
    }

    .alumniAdTop {
      width: 100%;
      padding: 0 16px;
    }

    .alumniAdStatement {
      width: 100%;
      padding: 0 16px;
      font-size: 14px;
    }

    section {
      flex-direction: column;
      height: auto;
      padding: 16px;
    }

    .videoTestimonials {
      width: 100%;
      height: auto;
      flex-direction: column;
    }

    .videoTestimonials > .videos {
      width: 100%;
      height: auto;
    }

    .videos > :first-child,
    .videos > :nth-child(2),
    .videos > :nth-child(3),
    .videos > :nth-child(4),
    .videos > :nth-child(5) {
      width: 100%;
      height: auto;
    }

    .alumniNames {
      width: 100%;
      padding: 16px;
    }
  }
}
