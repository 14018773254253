div.subscribe {
  display: flex;
  /* width: var(--ViewPortWidth, 1440px); */
  padding: 0px 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  background: #fff;
}

div.contain {
  display: flex;
  width: 100%;
  max-width: 1240px;
  padding: 40px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

div.subscribe-contents {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
}

div.subTop {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 503px;
  height: 220px;
  justify-content: center;
  align-items: center;
}

div.sub1 {
  display: flex;
  padding: 16px;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 80px;
  border: 1px solid rgba(235, 70, 14, 0.08);
  background: rgba(235, 70, 14, 0.15);
}

div.smsTop {
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
}

div.sub2 {
  display: flex;
  height: 124px;
  width: 503px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.sub2 > h1 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.sub2in {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.sub2in > h1 {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.36px;
}

div.subBottom {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

input.subInput {
  display: flex;
  width: 580px;
  padding: 10px 16px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Surface, #f7f7f7);
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

button.formButton {
  display: flex;
  width: 134px;
  height: 40px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 8px;
  background: #dd3b00;
}

div.subBottom > button.formButton > span {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}




@media only screen and (max-width: 600px) {

/* Mobile View CSS */
div.subscribe {
  display: flex;
  padding: 20px 16px; /* Adjusted padding for mobile */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px; /* Adjusted gap for mobile */
  background: #fff;
}

div.contain {
  display: flex;
  width: 100%;
  max-width: 90%; /* Adjusted max-width for mobile */
  padding: 20px 0px; /* Adjusted padding for mobile */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px; /* Adjusted gap for mobile */
}

div.subscribe-contents {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjusted gap for mobile */
  justify-content: center;
  align-items: center;
}

div.subTop {
  display: flex;
  flex-direction: column;
  gap: 12px; /* Adjusted gap for mobile */
  width: 100%;
  height: auto; /* Adjusted height for mobile */
  justify-content: center;
  align-items: center;
}

div.sub1 {
  display: flex;
  padding: 12px;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50%; /* Adjusted border-radius for mobile */
  border: 1px solid rgba(235, 70, 14, 0.08);
  background: rgba(235, 70, 14, 0.15);
}

div.smsTop {
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
}

div.sub2 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 12px; /* Adjusted gap for mobile */
}

.sub2 > h1 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 14px; /* Adjusted font size for mobile */
  font-weight: 500;
  line-height: normal;
}

.sub2in {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Adjusted gap for mobile */
  text-align: center;
}

.sub2in > h1 {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-size: 24px; /* Adjusted font size for mobile */
  line-height: 1.3; /* Adjusted line height for mobile */
}

div.subBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px; /* Adjusted gap for mobile */
  width: 100%;
}

input.subInput {
  width: 100%;
  max-width: 90%; /* Adjusted max-width for mobile */
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Surface, #f7f7f7);
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

button.formButton {
  width: 134px;
  height: 40px;
  padding: 9px 16px; /* Adjusted padding for mobile */
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 8px;
  background: #dd3b00;
}

div.subBottom > button.formButton > span {
  font-size: 14px; /* Adjusted font size for mobile */
  font-weight: 600;
  line-height: normal;
}



}
