

button {
  cursor: pointer;
}

button:hover {
  opacity: 0.7;
  transition: opacity .25s ease-in-out;
}



