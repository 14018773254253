.navbar {
  /* width: var(--viewPorthWidth, 1440px); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
  padding: 0px 16px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  border-bottom: 1px solid #00000014;
  background-color: #ffffff3e;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.container {
  max-width: 1240px;
  margin: auto;
  display: flex;
  padding: 30px 0px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hamburger {
  display: none;
}

li {
  cursor: pointer;
}

.container > img {
  flex-shrink: 0;
}

/* div.sided {
  width: 439px;
  height: 38px;
  display: flex;
  align-items: center;
  gap: 8px;
} */

ul {
  list-style: none;
  display: flex;
  gap: 8px;
}

li {
  text-decoration: none;
}

.navbar > :visited {
  text-decoration: none;
}

li.homebutton:hover {
  border-bottom: 2px solid #004a98;
}

li.cert:hover {
  border-bottom: 2px solid #004a98;
}

li.homebutton.active:hover,
li.cert.active:hover,
li.contacts.active:hover {
  border-bottom: none;
}

li.contacts:hover {
  border-bottom: 2px solid #004a98;
}

a {
  text-decoration: none;
}

span.home,
span.train,
span.cert,
span.contact {
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.active {
  border-radius: 8px;
  background: #004a98;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
}

.active > span {
  color: #fff;
}

li.homebutton {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

li.dropdown {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 4px;
  color: black;
}

.dropdown > span.train {
  display: grid;
  place-content: center;
  height: 100%;
}

.dropArrow {
  display: flex;
  width: 16px;
  height: 14px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #7a7a7a;
}

.dropdown-menu {
  position: absolute;
  top: 70px;
  width: 160px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.dropdown-menu > li {
  padding: 8px 16px;
  color: black;
}

.dropdown-menu > li:hover {
  border-bottom: 2px solid #004a98;
}

.dropdown-menu > * {
  color: var(--Background, #fff);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

li.cert {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
}

li.contacts {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
}

a {
  color: var(--Text-Secondary, #7a7a7a);
  text-decoration: none;
}

a:hover {
  color: #004a98;
}

a:visited {
  color: var(--Text-Secondary, #7a7a7a);
}

@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 50%;
    width: 100%;
  }

  .nhLogo {
    height: 20px;
  }

  .sided {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    gap: 20px;
    width: 100%;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .liContainer {
    display: none;
    flex-direction: column;
    gap: 0;
    width: 100%;
  }

  .liContainer.show {
    position: absolute;
    top: 70px;
    right: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 150px;
    height: auto;
    border-radius: 4px;
    box-shadow: #004a98;
    background-color: #fff;
  }

  .liContainer.show > a,
  .liContainer.show > li {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li {
    /* padding: 8px 16px; */
    width: 100%;
    text-align: left;
  }

  .dropdown {
    position: relative;
  }

  .dropArrow {
    position: absolute;
    top: 5px;
    right: 30px;
  }

  .dropdown-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    top: 0;
    width: 100%;
    border: none;
  }
}
