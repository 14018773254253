.DashboardTop {
  display: inline-flex;
  width: 1045px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 26px;
  gap: 8px;
}

.DashboardTop > h1 {
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.4px;
}

.DashTopIn {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.DashTopIn > div {
  display: flex;
  width: 136px;
  height: 100px;
  padding: 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 16px;
  border: none;
  background: var(--Surface, #f7f7f7);
}

.DashInfo1 h2,
.DashInfo2 h2,
.DashInfo3 h2,
.DashInfo4 h2,
.DashInfo5 h2,
.DashInfo6 h2 {
  color: var(--Text-Primary, #121212);
  font-family: "Manrope", sans-serif;
  align-self: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.48px;
}

.DashInfo1 span,
.DashInfo2 span,
.DashInfo3 span,
.DashInfo4 span,
.DashInfo5 span,
.DashInfo6 span {
  color: var(--Surface-Shade, #8a8a8a);
  align-self: center;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.DashboardBottom {
  display: flex;
  width: 1045px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: 48px;
}

.DashboardBottom > h1 {
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.4px;
}

.DashBottomInfo {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.DBInfo1,
.DBInfo2,
.DBInfo3,
.DBInfo4 {
  display: flex;
  min-width: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 11px;
  flex: 1 0 0;
  cursor: pointer;
}

.DBInfo1 > p,
.DBInfo2 > p,
.DBInfo3 > p,
.DBInfo4 > p {
  color: var(--Surface-Shade, #8a8a8a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.32px;
}

.inDBInfo1,
.inDBInfo2,
.inDBInfo3,
.inDBInfo4 {
  display: flex;
  padding: 48px 60px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Surface, #f7f7f7);
}

.inDBInfo1 > span,
.inDBInfo2 > span,
.inDBInfo3 > span,
.inDBInfo4 > span {
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
}
