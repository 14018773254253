* {
  padding: 0;
  margin: 0;
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #101a25;
  padding: 25px 0px;
  justify-content: center;
  align-items: center;
}

.footerContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px;
  height: 252px;
  max-width: 1240px;
  /* width: 100%; */
  gap: 8px;
  align-self: stretch;
  margin: auto;
}

div.footerTop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  max-width: 1240px;
  max-height: 188px;
  padding: 32px 0px;
}

div.footerContents {
  display: flex;
  flex-direction: row;
  width: 1240px;
  align-items: flex-start;
  gap: 122px;
  margin: auto;
}

div.footerContents {
  list-style-type: none;
}

div.footerLogo {
  width: 219px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

img.newHor {
  display: flex;
  width: 228.05px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

div.footerCol1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

div.footerCol1 > h1 {
  color: var(--Background, #fff);
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

div.col1menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 86px;
  height: 144px;
}

.col1menu:nth-child(1),
.col1menu:nth-child(2),
.col1menu:nth-child(3),
.col1menu:nth-child(4) {
  display: flex;
  padding: 2px 0px;
  align-items: flex-start;
  gap: 8px;
}

.col1menu:nth-child(1) > li > span,
.col1menu:nth-child(2) > li > span,
.col1menu:nth-child(3) > li > span,
.col1menu:nth-child(4) > li > span {
  color: #979ea6;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div.footerCol2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

div.footerCol2 > h1 {
  color: var(--Background, #fff);
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

div.col2menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 86px;
  height: 144px;
}

.col2menu:nth-child(1),
.col2menu:nth-child(2),
.col2menu:nth-child(3),
.col2menu:nth-child(4) {
  display: flex;
  padding: 2px 0px;
  align-items: flex-start;
  gap: 8px;
}

.col2menu:nth-child(1) > li > span,
.col2menu:nth-child(2) > li > span,
.col2menu:nth-child(3) > li > span,
.col2menu:nth-child(4) > li > span {
  color: #979ea6;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div.footerCol3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  width: 219px;
  height: 188px;
}

div.footerCol3 > h1 {
  color: var(--Background, #fff);
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

div.col3menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 86px;
  height: 144px;
}

.col3menu:nth-child(1),
.col3menu:nth-child(2),
.col3menu:nth-child(3),
.col3menu:nth-child(4) {
  display: flex;
  padding: 2px 0px;
  align-items: flex-start;
  gap: 8px;
}

.col3menu:nth-child(1) > li > span,
.col3menu:nth-child(2) > li > span,
.col3menu:nth-child(3) > li > span,
.col3menu:nth-child(4) > li > span {
  color: #979ea6;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
li.ct1,
li.ct2,
li.ct3,
li.ct4 {
  display: flex;
  align-items: center;
  gap: 8px;
}

img.fb,
img.x,
img.lkdn,
img.insta {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

div.footerBottom {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: #101a25;
}

div.fbContents {
  display: flex;
  width: 1240px;
  max-width: 1240px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

div.fbContents > p {
  color: var(--Background, #fff);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.linkTxt {
  color: #979ea6;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.linkTxt:hover {
  color: white;
}

.ct4,
.ct3,
.ct2,
.ct1 {
  color: #979ea6;
}

.ct4:hover,
.ct3:hover,
.ct2:hover,
.ct1:hover {
  color: white;
}

.alnk {
  display: flex;
  align-items: center;
  gap: 8px;
}




@media only screen and (max-width: 600px) {


.footer {
  display: block;
  flex-direction: column;
  align-items: center;
  background: #101a25;

  height: 770px !important;
  justify-content: center;
  align-items: center;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 0px 16px;
  max-width: 90%;
}

div.footerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 32px 0px;
  max-width: 90%;
}

div.footerContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 90%;
}

div.footerLogo,
div.footerCol1,
div.footerCol2,
div.footerCol3 {
  width: 100%;
  max-width: 90%;
}

img.newHor {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2.5em;
}

div.footerCol1,
div.footerCol2,
div.footerCol3 {
  gap: 16px;
}

div.footerCol1 > h1,
div.footerCol2 > h1,
div.footerCol3 > h1 {
  color: var(--Background, #fff);
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}

div.col1menu,
div.col2menu,
div.col3menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.col1menu > li,
.col2menu > li,
.col3menu > li {
  display: flex;
  align-items: center;
  gap: 8px;
}

img.fb,
img.x,
img.lkdn,
img.insta {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

div.footerBottom {
  display: block;
  flex-direction: row;

}

div.fbContents {
  display: block;
  position: absolute;
margin-top: 30em;
  max-width: 90%;
}

div.fbContents > p {
  color: var(--Background, #fff);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.linkTxt {
  color: #979ea6;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.linkTxt:hover {
  color: white;
}

.ct4,
.ct3,
.ct2,
.ct1 {
  color: #979ea6;
}

.ct4:hover,
.ct3:hover,
.ct2:hover,
.ct1:hover {
  color: white;
}

.alnk {
  display: flex;
  align-items: center;
  gap: 8px;
}

}