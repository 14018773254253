div.blog {
  display: flex;
  /* width: var(--ViewPortWidth, 1440px); */
  padding: 6px 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background: #f4f4f5;
}

div.blogContainer {
  display: flex;
  width: 100%;
  max-width: 1240px;
  padding: 40px 0px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

div.blogTop {
  display: flex;
  width: 503px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.blogTop > h1 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

div.blogTbottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.blogTbottom > p {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.36px;
}

div.blogBottom {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}

div.bottom1B {
  display: flex;
  width: 292px;
  height: 329px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Neutral-100, #eee);
}

h1.bottom1B-head {
  display: flex;
  height: 86px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

h1.bottom1B-head > span {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope";
  align-self: stretch;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 150% */
  text-decoration-line: underline;
}

img.blogimg1 {
  /* flex: 1 0 0; */
  width: 292px;
  height: 176px;
  align-self: stretch;
  object-fit: cover;
  /* object-position: 20%; */
}

div.inbottom3 {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #eee;
}

div.inbottom3 > p {
  color: var(--Neutral-500, var(--Surface-Shade, #8a8a8a));
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

@media only screen and (max-width: 600px) {
  /* Mobile View CSS */
  div.blog {
    display: flex;
    padding: 20px 16px; /* Adjusted padding for mobile */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px; /* Adjusted gap for mobile */
    background: #f4f4f5;
  }

  div.blogContainer {
    display: flex;
    width: 100%;
    max-width: 90%; /* Adjusted max-width for mobile */
    padding: 20px 0px; /* Adjusted padding for mobile */
    margin: auto;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Adjusted gap for mobile */
  }

  div.blogTop {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 16px; /* Adjusted gap for mobile */
  }

  .blogTop > h1 {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    font-size: 14px; /* Adjusted font size for mobile */
    font-weight: 500;
    line-height: normal;
  }

  div.blogTbottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    text-align: center; /* Center align text */
  }

  .blogTbottom > p {
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    font-family: "Manrope", sans-serif;
    font-size: 28px; /* Adjusted font size for mobile */
    font-weight: 500;
    line-height: 1.3; /* Adjusted line height for mobile */
  }

  div.blogBottom {
    display: flex;
    flex-wrap: wrap; /* Allow bottom elements to wrap on smaller screens */
    justify-content: center;
    gap: 16px; /* Adjusted gap for mobile */
    width: 100%;
  }

  div.bottom1B {
    display: flex;
    width: 100%; /* Adjusted width for mobile */
    flex-direction: column;
    align-items: center;
    gap: 16px; /* Adjusted gap for mobile */
    border-radius: 8px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: var(--Neutral-100, #eee);
    margin-bottom: 2em;
  }

  h1.bottom1B-head {
    display: flex;
    height: 86px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px; /* Adjusted gap for mobile */
    align-self: stretch;
    text-align: center; /* Center align text */
  }

  h1.bottom1B-head > span {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope";
    font-size: 18px; /* Adjusted font size for mobile */
    font-weight: 500;
    line-height: 1.5; /* Adjusted line height for mobile */
    text-decoration-line: underline;
  }

  img.blogimg1 {
    width: 100%; /* Adjusted width for mobile */
    height: 171px;
    object-fit: cover; /* Maintain aspect ratio */
  }

  div.inbottom3 {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px; /* Adjusted gap for mobile */
    align-self: stretch;
    border-top: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: #eee;
  }

  div.inbottom3 > p {
    color: var(--Neutral-500, var(--Surface-Shade, #8a8a8a));
    font-family: "Manrope", sans-serif;
    font-size: 14px; /* Adjusted font size for mobile */
    font-weight: 400;
    line-height: 1.5; /* Adjusted line height for mobile */
    text-decoration-line: underline;
  }
}
