.retail-course-details {
  display: flex;
  /* width: var(--ViewPortWidth, 1440px); */
  /* padding: 0px 16px; */
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background: #fff;
}

.individual-retail-courses {
  display: flex;
  /* max-width: 1240px; */
  width: 100%;
  /* padding: 40px 0px; */
  flex-direction: column;
  justify-content: center;
  gap: 32px;
}

.coursesRetailTops {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  gap: 200px;
  width: 80%;
}

.coursesRetailTops > h1 {
  padding-top: 120px;
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 550;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.36px;
}

.coursesRetailTops > img {
  width: 400px;
  height: 400px;
}

.coursesRetailContenting {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 400px;
  margin-left: 124px;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 20px;
}

.coursesRetailContenting > p {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 36px; /* 122.222% */
  letter-spacing: -0.36px;
}

.coursesRetailContenting > div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.courseRetailBanner {
  height: 60vh;
  color: #fff;
  background-image: url(/src/assets/images/college-students-cramming-outdoor.jpg);
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
}

.courseRetailBannerContent {
  background-image: linear-gradient(
    to right,
    #041f3c,
    #041f3c,
    #041f3c,
    rgba(43, 88, 118, 0.2)
  );
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 5%;
}

.courseRetailBanner h3 {
  font-weight: 900;
  font-size: 25px;
}

.world {
  background-color: white;
  color: #de3b00;
  padding: 15px;
}

.institute {
  background-color: #de3b00;
  color: #fff;
  padding: 15px;
}

@media only screen and (max-width: 600px) {
  .retail-course-details {
    display: flex;
    width: 100%;
    /* padding: 0px 16px; */
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    background: #fff;
  }

  .individual-retail-courses {
    display: flex;
    padding: 0px;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }

  .courseRetailBanner {
    height: 30vh;
    color: #fff;
    background-image: url(/src/assets/images/empowering-women-leadership-roles.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .cousreRetailBannerContent {
    background-image: linear-gradient(
      to right,
      #041f3c,
      #041f3c,
      #041f3c,
      rgba(43, 88, 15, 0.2)
    );
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .courseRetailBanner h3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    font-weight: 900;
    font-size: 25px;
  }

  .coursesRetailTops {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
    padding-left: 20px;
    margin: 0px;
    gap: 5px;
    width: 100%;
  }

  .coursesRetailTops > h1 {
    width: 85%;
    padding-top: 10px;
    padding-bottom: 20px;
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 550;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.36px;
  }

  .coursesRetailTops > img {
    width: 300px;
    height: 300px;
  }

  .coursesRetailContenting {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 25px;
    width: 100%;
    margin-left: 0px;
    padding-bottom: 10px;
    padding-left: 20px;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 20px;
  }

  .coursesRetailContenting > p {
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: 36px; /* 122.222% */
    letter-spacing: -0.36px;
  }

  .coursesRetailContenting > div {
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 15px;
  }
}
