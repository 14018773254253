.courseTableContainer {
  width: 1045px;
  margin-top: 26px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.courseTabHead {
  height: 56px;
  margin: 0;
  padding: 0;
  background: #f2f6fa;
}

.courseTabhead1,
.courseTabhead3,
.courseTabhead4 {
  position: relative;
  width: 201px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  align-items: center;
  align-self: stretch;
}

.courseTabhead2 {
  position: relative;
  width: 232.5px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  align-items: center;
  align-self: stretch;
}

.courseTabhead5 {
  position: relative;
  width: 120px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  align-items: center;
  align-self: stretch;
}

.courseTabhead1 span,
.courseTabhead2 span,
.courseTabhead3 span,
.courseTabhead4 span,
.courseTabhead5 span,
.courseTabhead6 span {
  position: absolute;
  left: 16px;
  top: 16px;
  color: var(--Text-Primary, #121212);
  max-width: 117px;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}

.sortIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
}

.courseTabbodyrow1,
.courseTabbodyrow2,
.courseTabbodyrow3,
.courseTabbodyrow4 {
  width: 201px;
  height: 40px;
  padding: 8px 16px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  align-items: center;
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}

.courseTabbodyrow5 {
  width: 100px;
  height: 40px;
  padding: 8px 16px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  align-items: center;
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}

.pagination {
  margin-top: 56px;
  margin-left: 358px;
  display: inline-flex;
  max-width: 504px;
  height: 36px;
  align-items: flex-start;
  gap: 8px;
}

.pagination > button {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.32px;
}
