.schtopdawg {
  height: 409px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.schcontainer {
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 408px;
  min-height: 400px;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

img.imgschimg {
  width: 100%;
  height: auto;
  align-self: stretch;
}

@media screen and (max-width: 600px) {
  .schtopdawg {
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .schcontainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    height: auto;
    min-height: 200px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  img.imgschimg {
    width: 100%;
    height: auto;
    align-self: stretch;
  }
}
