div.trainingCover {
  display: flex;
  height: 576px;
  /* width: var(--ViewPortWidth, 1440px); */
  padding: 56px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #f4f4f5;
}

div.trainingContainer {
  display: flex;
  width: 100%;
  max-width: 1240px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin: auto;
}

div.options-inner {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

div.options-list {
  display: flex;
  width: 190px;
  padding-right: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
  border-right: 1px solid #ced2d5;
}

.option {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer; /* Add this line to show that it's clickable */
}

h1.optionshead {
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.option.active {
  border-radius: 16px;
  background: #004a98;
  width: 173px;
  height: 38px;
}

.option.active > h1 {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

div.content {
  display: flex;
  width: 1026px;
  height: 464px;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  flex: 1 0 0;
}

img.stylemage {
  align-self: stretch;
  border-radius: 8px;
  object-fit: cover;
}

div.contentButtom {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

div.contentBottom1 > h2.content-heading {
  color: var(--Text-Primary, #121212);
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

div.contentBottom1 > p {
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.contentButtom a > button.learn {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #dd3b00;
  border: none;
}

div.contentBottom1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

button.learn > span {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}





@media only screen and (max-width: 600px) {
/* Base CSS */

/* Common styles for all screen sizes */

/* Mobile View CSS */
div.trainingCover {
  display: flex;
  height: auto; /* Adjusted height for mobile */
  padding: 32px 16px; /* Adjusted padding for mobile */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px; /* Adjusted gap for mobile */
  background: #f4f4f5;
}

div.trainingContainer {
  display: flex;
  width: 100%;
  max-width: 100%; /* Adjusted max-width for mobile */
  flex-direction: column;
  align-items: center; /* Adjusted alignment for mobile */
  gap: 16px; /* Adjusted gap for mobile */
  margin: auto;
}

div.options-inner {
  display: flex;
  align-items: center; /* Adjusted alignment for mobile */
  flex-direction: column; /* Adjusted direction for mobile */
  gap: 16px; /* Adjusted gap for mobile */
  align-self: stretch;
}

div.options-list {
  display: flex;
  width: 100%;
  padding-right: 0; /* Removed padding for mobile */
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Adjusted alignment for mobile */
  gap: 16px; /* Adjusted gap for mobile */
  border-right: none; /* Removed border for mobile */
}

.option {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer; /* Add this line to show that it's clickable */
  width: 100%; /* Adjusted width for mobile */
}

h1.optionshead {
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 14px; /* Adjusted font size for mobile */
  font-weight: 500;
  line-height: normal;
  text-align: center; /* Adjusted alignment for mobile */
}

.option.active {
  border-radius: 16px;
  background: #004a98;
  width: 100%; /* Adjusted width for mobile */
  height: auto; /* Adjusted height for mobile */
}

.option.active > h1 {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 14px; /* Adjusted font size for mobile */
  font-weight: 500;
  line-height: normal;
}

div.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center; /* Adjusted alignment for mobile */
  gap: 16px; /* Adjusted gap for mobile */
}

img.stylemage {
  align-self: stretch;
  border-radius: 8px;
  object-fit: cover;
  width: 100%; /* Adjusted width for mobile */
  max-width: 100%; /* Ensure image fits within container */
  height: auto; /* Maintain aspect ratio */
}

div.contentButtom {
  display: flex;
  flex-direction: column; /* Adjusted direction for mobile */
  align-items: center; /* Adjusted alignment for mobile */
  gap: 16px; /* Adjusted gap for mobile */
  width: 100%; /* Adjusted width for mobile */
}

div.contentBottom1 > h2.content-heading {
  color: var(--Text-Primary, #121212);
  font-family: "Manrope", sans-serif;
  font-size: 16px; /* Adjusted font size for mobile */
  font-weight: 700;
  line-height: normal;
  text-align: center; /* Adjusted alignment for mobile */
}

div.contentBottom1 > p {
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 14px; /* Adjusted font size for mobile */
  font-weight: 500;
  line-height: 1.5; /* Adjusted line height for mobile */
  text-align: center; /* Adjusted alignment for mobile */
}

.contentButtom a > button.learn {
  display: flex;
  padding: 10px 20px; /* Adjusted padding for mobile */
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #dd3b00;
  border: none;
  font-size: 14px; /* Adjusted font size for mobile */
  font-weight: 600;
  line-height: normal;
}

button.learn > span {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 14px; /* Adjusted font size for mobile */
  font-weight: 600;
}





}