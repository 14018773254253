.adminContainer {
  /* height: 100vh; */
}

.adminpageContents {
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
}

.outlethusk {
  flex: 1;
}
