div.testimonials {
  height: 544px;
  /* width: var(--viewPorthWidth, 1440px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  gap: 8px;
}

div.testContents {
  max-width: 1240px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  gap: 32px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

div.testTop {
  display: flex;
  flex-direction: column;
  width: 503px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

div.testTop > h1 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

div.testTopSub {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.testTopSub > h1 {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  text-align: center;
  font-family: Manrope;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.36px;
}

.testTopSub > h1 > span.fiftyk {
  color: #dd3b00;
}

div.testBottom {
  display: flex;
  max-width: 1240px;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}

div.bottom1 {
  display: flex;
  width: 292px;
  height: 308px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--Surface, #f7f7f7);
}

div.bottom1_in {
  display: flex;
  width: 260px;
  height: 196px;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.bottom1_in > p {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  flex: 1, 0, 0;
}

div.bottom1_in2 {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #eee;
}

div.bottom1_in2:nth-child(1) {
  color: var(--Neutral-700, #505050);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div.bottom1_in2:nth-child(2) {
  color: var(--Neutral-500, var(--Surface-Shade, #8a8a8a));
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div.bottom2 {
  display: flex;
  width: 608px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat; */
}

div.bottom3 {
  display: flex;
  width: 292px;
  height: 308px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--Surface, #f7f7f7);
}

div.bottom3inside {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.bottom3inside > p {
  color: var(--Neutral-700, #505050);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.32px;
  flex: 1 0 0;
}

div.bottom3inside2 {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #eee;
}

div.bottom3inside2:nth-child(1) {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

div.bottom3inside2:nth-child(2) {
  color: var(--Neutral-500, var(--Surface-Shade, #8a8a8a));
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}






@media only screen and (max-width: 600px) {

.bottom2 {
  display: none !important;
}


.bottom1 {
  display: none !important;
}



/* Mobile View CSS */

div.testTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px; /* Adjusted gap for mobile */
  width: 100%;
}

div.testTop > h1 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 14px; /* Adjusted font size for mobile */
  font-weight: 500;
  line-height: normal;
}

div.testTopSub {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px; /* Adjusted gap for mobile */
  width: 100%;
}

.testTopSub > h1 {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  text-align: center;
  font-family: Manrope;
  font-size: 28px; /* Adjusted font size for mobile */
  font-weight: 500;
  line-height: 1.3; /* Adjusted line height for mobile */
  padding: 0 40px;
}


div.bottom1_in2:nth-child(1) > p,
div.bottom3inside2:nth-child(1) > p {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 14px; /* Adjusted font size for mobile */
  font-weight: 400;
  line-height: 1.5; /* Adjusted line height for mobile */
  text-align: center !important; /* Center align text */
}

div.bottom1_in2:nth-child(2) > p,
div.bottom3inside2:nth-child(2) > p {
  color: var(--Neutral-500, var(--Surface-Shade, #8a8a8a));
  font-family: "Manrope", sans-serif;
  font-size: 12px; /* Adjusted font size for mobile */
  font-weight: 400;
  line-height: 1.5; /* Adjusted line height for mobile */
  text-align: center; /* Center align text */
}

}