section.comtiahusk {
  display: flex;
  margin-top: 75px;
  padding: 0px 150px;
  flex-direction: column;
  align-items: center;
}

.comtiacontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.comptiaone {
  display: flex;
  width: 420px;
  gap: 20px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.comptiaone h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #0c0c0c;
  font-family: "Manrope";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
  align-self: stretch;
}

.comptiaone p {
  display: flex;
  padding-bottom: 20px;
  width: 385px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.comptiaone button {
  display: flex;
  padding: 15px;
  gap: 2.5px;
  justify-content: flex-start;
  align-items: center;
  border: none;
  border-radius: 2px;
  background-color: #eb460e;
}

.comptiaone button > span {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;
}

.comptiatwo {
  height: 256px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
}

.comptiatwo > img {
  width: 128.7px;
  height: 128.7px;
}

hr {
  display: flex;
  margin-top: 75px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid #f2b287;
}

@media only screen and (max-width: 600px) {
  /* Mobile View CSS */
  section.comtiahusk {
    margin-top: 30px; /* Adjusted margin-top for mobile */
    padding: 0 20px; /* Adjusted padding for mobile */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .comtiacontainer {
    display: flex;
    flex-direction: column; /* Changed to column layout for mobile */
    justify-content: center; /* Centered items for mobile */
    align-items: center; /* Centered items for mobile */
    align-self: stretch;
  }

  .comptiaone {
    display: flex;
    width: 100%; /* Adjusted width for mobile */
    gap: 20px;
    padding: 10px;
    flex-direction: column;
    align-items: center; /* Centered items for mobile */
    align-self: stretch;
  }

  .comptiaone h1 {
    color: #0c0c0c;
    font-family: "Manrope", sans-serif;
    font-size: 24px; /* Adjusted font size for mobile */
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* Adjusted line height for mobile */
    text-align: center; /* Centered text for mobile */
    margin-bottom: 10px; /* Adjusted margin for mobile */
  }

  .comptiaone p {
    color: #0c0c0c;
    width: 100%; /* Adjusted width for mobile */
    padding-bottom: 20px;
    font-family: "Manrope", sans-serif;
    font-size: 16px; /* Adjusted font size for mobile */
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* Adjusted line height for mobile */
    text-align: center; /* Centered text for mobile */
  }

  .comptiaone button {
    padding: 15px;
    gap: 5px; /* Adjusted gap for mobile */
    justify-content: center; /* Centered button text for mobile */
    align-items: center;
    border: none;
    border-radius: 2px;
    background-color: #eb460e;
    width: 100%; /* Adjusted width for mobile */
  }

  .comptiaone button > span {
    color: #fff;
    font-family: "Manrope", sans-serif;
    font-size: 16px; /* Adjusted font size for mobile */
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* Adjusted line height for mobile */
    text-align: center;
    text-transform: uppercase;
  }

  .comptiatwo {
    display: flex;
    flex-direction: column; /* Changed to column layout for mobile */
    gap: 20px;
    align-items: center; /* Centered items for mobile */
    align-self: stretch;
  }

  .comptiatwo > img {
    width: 100%; /* Adjusted width for mobile */
    max-width: 100.7px; /* Added max-width for mobile */
    height: auto; /* Ensures aspect ratio is maintained */
  }

  hr {
    margin-top: 40px; /* Adjusted margin-top for mobile */
    border: 1px solid #f2b287;
  }
}
