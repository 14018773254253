.signUpContainer {
  display: flex;
  height: 100vh;
  margin: auto;
  justify-content: center;
  align-items: center;
  background: var(--Surface, #f7f7f7);
}

.signUpFlask {
  display: flex;
  height: 330px;
  width: 492px;
  padding: 50px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
  box-shadow: 0px 45px 250px 0px rgba(17, 33, 39, 0.1),
    0px -4px 150px 0px rgba(35, 56, 65, 0.05);
}

.signUpFlask > h1 {
  color: var(--Text-Primary, #121212);
  font-family: "Manrope", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.inputEnv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.signUpMail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.signUpMail > label {
  color: var(--Text-Primary, #121212);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  align-self: stretch;
}

.signUpMail > input {
  display: flex;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
}

.signUpToken {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.signUpToken > label {
  color: var(--Text-Primary, #121212);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  align-self: stretch;
}

.signUpToken > input {
  display: flex;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
}

div.signUpDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.signUpDiv > button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #004a98;
  color: var(--Background, #fff);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
