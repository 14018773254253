.error {
  height: 100vh;
  display: grid;
  place-content: center;
  justify-items: center;
  padding: 0 20%;
  gap: 1em;
  text-align: center;
}

.ourCourses {
  color: #ffffff;
  background-color: #004A98;
  padding: 10px 20px;
  border-radius: 8px;
  width: 20vw;
}

.backHome {
  color: #004A98;
  background-color: #F2F6FA;
  padding: 10px 20px;
  border-radius: 8px;
  width: 20vw;
}

p{
  color: #7A7A7A;
}