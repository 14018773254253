div.shell {
  display: flex;
  /* width: var(--ViewPortWidth, 1440px); */
  margin: auto;
  padding: 0px 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background: #f7f7f7;
}

div.innershell {
  display: flex;
  max-width: 1240px;
  width: 100%;
  padding: 40px 0px;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}

div.shell1 {
  display: flex;
  width: 360px;
  height: 596px;
  padding: 100px 124px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #f4f4f5;
}

div.shell1top {
  display: flex;
  width: 360px;
  height: 100px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.shell1top > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.shell1top > h1 > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.36px;
}

p.shell1topwords {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

div.shellbottom {
  display: flex;
  width: 360px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}

.shellform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

Form > span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

span > label {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

span > input {
  display: flex;
  padding: 10px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Surface, #f7f7f7);
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

span > textarea {
  display: flex;
  height: 120px;
  padding: 10px 16px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Surface, #f7f7f7);
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

.shellbottom > button {
  display: flex;
  width: 360px;
  height: 40px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-items: stretch;
  border: none;
  border-radius: 8px;
  background: #dd3b00;
}

button > span {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

div.shell2 {
  display: flex;
  padding: 80px 124px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.shell2 > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.shell2 > h1 > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.36px;
}

div.shell2contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

div.shell2top {
  display: flex;
  width: 360px;
  height: 128px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
}

.shell2top > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.shell2top > h1 > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 220% */
  letter-spacing: -0.2px;
}

div.shell2top2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.shell2top2 > h2 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

div.shell2top2 > h5 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div.shell2top2 > h6 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

div.shell2mid {
  display: flex;
  width: 360px;
  height: 128px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
}

.shell2mid > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.shell2mid > h1 > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 220% */
  letter-spacing: -0.2px;
}

div.shell2mid2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.shell2mid2 > h2 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

div.shell2mid2 > h5 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div.shell2mid2 > h6 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

div.shell2end {
  display: flex;
  width: 360px;
  height: 128px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
}

.shell2end > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.shell2end > h1 > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 220% */
  letter-spacing: -0.2px;
}

div.shell2end2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.shell2end2 > h2 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

div.shell2end2 > h5 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div.shell2end2 > h6 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
