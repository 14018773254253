div.coursesRetailContainer {
  display: flex;
  /* width: var(--ViewPortWidth, 1440px); */
  padding: 0px 16px;
  height: 678px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background: #fff;
}

div.courseRetailshell {
  display: flex;
  max-width: 1240px;
  width: 100%;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

div.courseRetailTop {
  display: flex;
  flex-direction: column;
  width: 503px;
  height: 96px;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

h1.ctopRetailheading {
  display: flex;
  width: 499px;
  height: 44px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

h1.ctopRetailheading > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.36px;
}

p.cmidRetailheading {
  display: flex;
  width: 85px;
  height: 20px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #eee;
}

p.cmidRetailheading > span {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

div.popCourseRetailContainer {
  display: flex;
  /* margin-left: 50px; */
  width: 1240px;
  height: 470px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

div.popCourseRetailList {
  display: flex;
  width: 282px;
  height: 223px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Neutral-100, #eee);
}

img.popCourseRetailImg {
  /* flex: 1 0 0; */
  align-self: stretch;
  width: 282px;
  height: 151px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

div.popCourseRetailDiv {
  width: 248px;
  height: 20px;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #eee;
}

.popCourseRetailDiv > p {
  color: var(--Neutral-500, var(--Surface-Shade, #8a8a8a));
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}

@media only screen and (max-width: 600px) {
  div.coursesRetailContainer {
    display: flex;
    padding: 0px 16px;
    height: auto; /* Adjust height to auto for responsiveness */
    flex-direction: column;
    align-items: center;
    gap: 8px;
    background: #fff;
  }

  div.courseRetailshell {
    display: flex;
    max-width: 100%; /* Adjust max-width to fill the viewport */
    width: 100%;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  div.courseRetailTop {
    display: flex;
    flex-direction: column;
    width: 90%; /* Adjust width to fill the viewport */
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  h1.ctopRetailheading {
    display: flex;
    width: 100%; /* Adjust width to fill the viewport */
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  h1.ctopRetailheading > span {
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-size: 24px; /* Adjust font size for mobile view */
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* Adjust line height for mobile view */
    letter-spacing: -0.36px;
  }

  p.cmidRetailheading {
    display: flex;
    margin-top: 12px;
    width: 60px;
    height: 20px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: #eee;
  }

  p.cmidRetailheading > span {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    font-size: 14px; /* Adjust font size for mobile view */
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  div.popCourseRetailContainer {
    display: flex;
    width: 100%;
    height: auto; /* Adjust height to auto for responsiveness */
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    flex-wrap: wrap;
  }

  div.popCourseRetailList {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: var(--Neutral-100, #eee);
  }

  img.popCourseRetailImg {
    align-self: stretch;
    width: 100%;
    height: auto;
  }

  div.popCourseRetailDiv {
    width: 90%;
    height: 20px;
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    border-top: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: #eee;
  }

  .popCourseRetailDiv > p {
    color: var(--Neutral-500, var(--Surface-Shade, #8a8a8a));
    font-family: "Manrope", sans-serif;
    font-size: 14px; /* Adjust font size for mobile view */
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
  }
}
