.fcomponentShell {
  display: flex;
  padding: 20px 150px;
  flex-direction: column;
  align-items: center;
}

.fcomponentinnerhusk {
  display: flex;
  margin: auto;
  gap: 0;
  flex-direction: row;
  max-width: 1140px;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.innerfcomponentinnerhusk {
  display: flex;
  width: 670.52px;
  padding: 30px 20px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.innerfcomponentinnerhusk h3 {
  display: flex;
  width: 430px;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.innerfcomponentinnerhusk h3 > span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #182559;
  font-family: "Manrope", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.testparacert {
  display: flex;
  width: 630.52px;
  gap: 20px;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.testparacert > p {
  color: #54595f;
  width: 623px;
  font-family: "Manrope", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px;
}

.imghusktest {
  display: flex;
  width: 469.47px;
  padding: 44.6px 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;
}

.imghusktest > img {
  width: 449.47px;
  height: 299.78px;
}

.innerfcomponentinnerhusk > button {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: flex-start;
  border: none;
  border-radius: 2px;
  background: #eb460e;
  color: #fff;
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}





@media only screen and (max-width: 600px) {
/* Mobile View CSS */
.fcomponentShell {
  display: flex;
  padding: 20px 20px; /* Adjusted padding for mobile */
  flex-direction: column;
  align-items: center;
}

.fcomponentinnerhusk {
  display: flex;
  margin: auto;
  gap: 0;
  flex-direction: column; /* Changed to column layout for mobile */
  max-width: 100%; /* Adjusted max-width for mobile */
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.innerfcomponentinnerhusk {
  display: flex;
  width: 100%; /* Adjusted width for mobile */
  padding: 20px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Centered items for mobile */
  flex: 1 0 0;
  align-self: stretch;
}

.innerfcomponentinnerhusk h3 {
  display: flex;
  width: 100%; /* Adjusted width for mobile */
  padding-bottom: 20px;
  flex-direction: column;
  align-items: center; /* Centered items for mobile */
  align-self: stretch;
  text-align: center; /* Centered text for mobile */
}

.innerfcomponentinnerhusk h3 > span {
  color: #182559;
  font-family: "Manrope", sans-serif;
  font-size: 24px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* Adjusted line height for mobile */
}

.testparacert {
  display: flex;
  width: 100%; /* Adjusted width for mobile */
  gap: 20px;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: center; /* Centered items for mobile */
  align-self: stretch;
}

.testparacert > p {
  color: #54595f;
  max-width: 100%; /* Adjusted max-width for mobile */
  font-family: "Manrope", sans-serif;
  font-size: 16px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* Adjusted line height for mobile */
}

.imghusktest {
  display: flex;
  width: 100%; /* Adjusted width for mobile */
  padding: 20px 0; /* Adjusted padding for mobile */
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;
}

.imghusktest > img {
  width: 100%; /* Adjusted width for mobile */
  height: auto;
}

.innerfcomponentinnerhusk > button {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  background: #eb460e;
  color: #fff;
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 16px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* Adjusted line height for mobile */
  width: 100%; /* Adjusted width for mobile */
}


}