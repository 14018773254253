.session {
  padding: 40px 10%;
}

.session-info {
  display: flex;
}

.left-column,
.right-column {
  width: 50%;
}

.left-column {
  /* border-right: 1px solid #ccc; */
  padding-right: 20px;
}

.right-column {
  padding-left: 20px;
}

.right-column button {
  width: 15em;
  padding: 15px 30px;
  background-color: #004a98;
  color: #fff;
  border: none;
  border-radius: 3px;
}

.techXtra {
  display: grid;
  place-content: center;
  gap: 30px;
  height: 60vh;
  background-color: #004a98;
  background-image: url(../../assets/images/div.elementor-background-overlay.png);
  background-position: center bottom;
}

.techXtra_info {
  height: 70vh;
  padding: 40px 50% 40px 10%;
  display: grid;
  place-content: center;
  gap: 25px;
  background-image: url(../../assets/images/bg-blocks-300x300.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 40%;
  color: #fff;
}

.techXtra_info p {
  color: #fff;
}

.techXtra_info button {
  width: 15em;
  padding: 15px 30px;
  background-color: #fb4d00;
  color: #fff;
  border: none;
  border-radius: 3px;
}

.summerTech {
  padding: 40px 10%;
  background-color: #f7f7f7;
  display: grid;
  place-content: center;
  gap: 20px;
}

.summerTech button {
  width: 15em;
  padding: 15px 30px;
  background-color: #fb4d00;
  color: #fff;
  border: none;
  border-radius: 3px;
}

.digitalHub {
  padding: 40px 10%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: center;
  gap: 20px;
}

.hubLeft {
  display: grid;
  align-items: center;
}

.hubRight {
  display: grid;
  place-content: center;
}

.robots {
  padding: 40px 10%;
  background-color: #f7f7f7;
  display: grid;
  place-content: center;
  gap: 20px;
}

.achievements {
  margin-top: 20px;
  display: grid;
  justify-items: center;
  color: #fff;
  background-image: url(../../assets/images/shiny-golden-trophy-against-wooden-backdrop.jpg);
  background-color: #1a1c8b;
  background-blend-mode: overlay;
  padding: 70px 10%;
}

.achievepoints {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 40px;
}

.points1,
.points2 {
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  color: #727272;
  padding: 35px;
}

.points ul {
  display: grid;
  gap: 20px;
}

.achievements-list {
  list-style: none;
  padding: 0;
}

.achievements-list li {
  margin-bottom: 10px;
  padding-left: 70px;
  position: relative;
}

.achievements-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 40px;
  height: 40px;
  background-image: url("../../assets/images/achievebul.png");
  background-size: cover;
}
