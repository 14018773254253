* {
  font-family: "Manrope", sans-serif;
}

.tetBanner {
  height: 50vh;
  display: grid;
  place-content: center;
  color: #ffffff;
  background-image: url(/src/assets/images/tetiary\ image.jpg);
  /* background-color: #042392;
  background-blend-mode: overlay; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 0;
}

.tetBanner::before {
  height: 51vh;
  content: "";
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  z-index: 1;
  background: #182559;
  opacity: 0.85;
}

.tetBanner h1 {
  z-index: 2;
}

.intro {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: center;
  padding: 10px 150px;
  line-height: 1.5em;
}

.introLeft {
  display: grid;
  place-content: center;
  gap: 1em;
}

.introRight {
  display: grid;
  place-content: center;
}

.programs {
  display: grid;
  justify-items: center;
  padding: 20px 16px;
  margin-bottom: 700px;
}

.partner img {
  object-fit: contain;
}

.progTitle {
  margin: 0px 50px 30px 50px;
}

.partner {
  display: grid;
  justify-items: center;
  padding: 40px 16px;
  /* margin-top: 1000px; */
  background-color: #f7f7f7;
}

.partTitle {
  margin: 0px 50px 30px 50px;
}

.why {
  padding: 70px 150px;
  display: grid;
  gap: 30px;
  margin-top: 750px;
}

.reasons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.reason {
  background-color: #f7f7f7;
  padding: 50px 15px 50px 32px;
  border-radius: 10px;
}

.reason3 {
  grid-column: 1 / span 2;
}

@media (max-width: 600px) {
  .tetBanner {
    height: 50vh;
    width: 100%;
    display: grid;
    place-content: center;
    color: #ffffff;
    background-image: url(/src/assets/images/tetiary\ image.jpg);
    /* background-color: #042392;
    background-blend-mode: overlay; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 0;
  }

  .tetBanner::before {
    height: 50vh;
    width: 100%;
    content: "";
    position: absolute;
    top: 85px;
    left: 0;
    right: 0;
    z-index: 1;
    background: #182559;
    opacity: 0.85;
  }

  .tetBanner h1 {
    z-index: 2;
    font-weight: 500;
  }

  .intro {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    place-content: center;
    padding: 0px;
    line-height: 1.5em;
  }

  .introLeft {
    order: 2;
    width: 90%;
    padding: 0px 5px 10px 10px;
  }

  .introRight {
    width: 100%;
    order: 1;
  }

  .introRight img {
    width: 300px;
    height: 300px;
  }

  .programs {
    margin-bottom: 50px;
  }

  .courseContainer {
    display: grid;
    grid-template-columns: 1fr; /* Change to one column */
    padding-left: 10px;
    gap: 20px; /* Add gap between items */
    justify-items: center; /* Center items horizontally */
    width: 100%;
  }

  .why {
    margin-top: 20px;
    padding: 20px;
  }
}
