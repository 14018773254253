.awstopdawg {
  margin-top: 10px;
  padding: 0px 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.awscerthusk {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 0 0;
}

.awsfirstrow {
  display: flex;
  width: 515.22px;
  gap: 20px;
  padding: 10px 10px 199.52px 10px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.awsheading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #0c0c0c;
  font-family: "Manrope", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
}

.awsfirstrow p {
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.awsfirstrow p > span:nth-child(1) {
  color: #0c0c0c;
  width: 450px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.awsfirstrow p > span:nth-child(2),
span:nth-child(3) {
  color: #0c0c0c;
  width: 426px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.awsfirstrow button {
  display: flex;
  padding: 15px;
  gap: 18px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 2px;
  border: none;
  background-color: #eb460e;
}

.awsfirstrow button > span {
  color: #fff;
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px; /* 100% */
  text-transform: uppercase;
}

.awssecondrow {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.awssecondrow div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 118.7px;
  padding: 10px;
}

.awssecondrow div > img {
  background: none;
}

hr {
  display: flex;
  padding: 0 150px;
  margin-top: 75px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid #f2b287;
}

@media only screen and (max-width: 600px) {
  /* Mobile View CSS */
  .awstopdawg {
    margin-top: 30px; /* Adjusted margin-top for mobile */
    padding: 0 20px; /* Adjusted padding for mobile */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .awscerthusk {
    display: flex;
    flex-direction: column; /* Changed to column layout for mobile */
    justify-content: center;
    align-items: center; /* Centered items for mobile */
    align-self: stretch;
    flex: 1 0 0;
  }

  .awsfirstrow {
    display: flex;
    width: 100%; /* Adjusted width for mobile */
    gap: 20px;
    padding: 10px 0; /* Adjusted padding for mobile */
    flex-direction: column;
    align-items: center; /* Centered items for mobile */
    align-self: stretch;
  }

  .awsheading {
    color: #0c0c0c;
    font-family: "Manrope", sans-serif;
    font-size: 24px; /* Adjusted font size for mobile */
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* Adjusted line height for mobile */
    text-align: center; /* Centered text for mobile */
  }

  .awsfirstrow p {
    padding-bottom: 20px;
    text-align: center; /* Centered text for mobile */
  }

  .awsfirstrow p > span {
    color: #0c0c0c;
    max-width: 100%; /* Adjusted max-width for mobile */
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* Adjusted line height for mobile */
  }

  .awsfirstrow button {
    padding: 15px;
    gap: 10px; /* Adjusted gap for mobile */
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: none;
    background-color: #eb460e;
    width: 100%; /* Adjusted width for mobile */
  }

  .awsfirstrow button > span {
    color: #fff;
    font-family: "Manrope", sans-serif;
    font-size: 16px; /* Adjusted font size for mobile */
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* Adjusted line height for mobile */
    text-transform: uppercase;
  }

  .awssecondrow {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: center;
    /* height: 100%; */
  }

  .awssecondrow div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* align-self: stretch; */
    /* width: 118.7px;
  padding: 10px; */
  }

  .awssecondrow div > img {
    background: none;
    width: 120px;
  }
}
