section.mtaHusk {
  display: flex;
  margin-top: 60px;
  padding: 0px 150px;
  flex-direction: column;
  align-items: center;
}

.mtacontainer {
  display: flex;
  height: 256.59px;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 0 0;
  margin-bottom: 40px;
}

.mtaimages {
  width: 524.8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.mtaimages img {
  width: 131.2px;
  height: 131.2px;
}

.mtawriteups {
  display: flex;
  width: 445.22px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.mtawriteups h1 {
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #0c0c0c;
  font-family: "Manrope";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
}

.mtawriteups p {
  display: flex;
  padding-bottom: 40px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #0c0c0c;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.mtawriteups button {
  display: flex;
  padding: 15px;
  gap: 2px;
  justify-content: center;
  align-items: flex-start;
  border: none;
  border-radius: 2px;
  background: #eb460e;
}

.mtawriteups button > img {
  padding-top: 2px;
}




@media only screen and (max-width: 600px) {  

  section.mtaHusk {
    display: none;
    margin-top: 30px; /* Adjusted margin for better spacing */
    padding: 0px 20px; /* Reduced padding for smaller screens */
    flex-direction: column;
    align-items: center;
  }
  
  .mtacontainer {
    display: flex;
    flex-direction: column; /* Changed to column layout for smaller screens */
    justify-content: flex-start;
    align-items: center; /* Centered items horizontally */
    margin-bottom: 20px; /* Adjusted margin for better spacing */
  }
  
  .mtaimages {
    display: flex;
    flex-wrap: wrap; /* Allowing images to wrap on smaller screens */
    justify-content: center; /* Centering images horizontally */
    gap: 10px; /* Added gap between images */
  }
  
  .mtaimages img {
    width: 10%; /* Reduced image size for smaller screens */
    height: auto; /* Reduced image size for smaller screens */
    max-width: 20% !important;
    display: none;
  }
  
  .mtawriteups {

    padding-left: 120px;

    max-width: 50%;
  }
  
  .mtawriteups h1 {
    padding-bottom: 10px; /* Reduced padding for smaller screens */
    text-align: center; /* Centering text */
  }
  
  .mtawriteups p {
    padding-bottom: 20px; /* Reduced padding for smaller screens */
    text-align: center; /* Centering text */
  }
  
  .mtawriteups button {
    padding: 10px 20px; /* Reduced padding for smaller screens */
    margin-top: 10px; /* Added margin for better spacing */
  }
  
  .mtawriteups button > img {
    padding-top: 2px;
  }
  

}