div.ret-catalogContainer {
  display: flex;
  /* width: var(--ViewPortWidth, 1440px); */
  padding: 0px 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background: #fff;
}

div.ret-catalogshell {
  display: flex;
  max-width: 1240px;
  width: 100%;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

div.ret-catTop {
  display: flex;
  flex-direction: column;
  width: 503px;
  height: 96px;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

h1.ret-topheading {
  display: flex;
  width: 499px;
  height: 44px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

h1.ret-topheading > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.36px;
}

p.ret-midheading {
  display: flex;
  width: 85px;
  height: 20px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #eee;
}

p.ret-midheading > span {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

div.ret-courseContainer {
  display: flex;
  width: 1240px;
  height: 470px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

div.ret-courseList {
  display: flex;
  width: 282px;
  height: 223px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Neutral-100, #eee);
}

img.ret-courseImg {
  /* flex: 1 0 0; */
  align-self: stretch;
  width: 282px;
  height: 151px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

div.ret-courseDiv {
  width: 248px;
  height: 20px;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #eee;
}

.ret-courseDiv > p {
  color: var(--Neutral-500, var(--Surface-Shade, #8a8a8a));
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}

@media only screen and (max-width: 600px) {
  div.ret-catalogContainer {
    display: flex;
    /* width: var(--ViewPortWidth, 1440px); */
    padding: 0px 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    background: #fff;
  }

  div.ret-catalogshell {
    display: flex;
    max-width: 600px;
    width: 100%;
    padding: 10px 10px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  div.ret-catTop {
    display: flex;
    flex-direction: column;
    width: 483px;
    height: 96px;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  h1.ret-topheading {
    display: flex;
    width: 469px;
    height: 44px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  h1.ret-topheading > span {
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -0.36px;
  }

  p.ret-midheading {
    display: flex;
    width: 85px;
    height: 20px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: #eee;
  }

  div.ret-courseContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    align-content: flex-start;
    gap: 24px;
    flex-wrap: wrap;
  }

  div.ret-courseList {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: var(--Neutral-100, #eee);
  }

  img.ret-courseImg {
    /* flex: 1 0 0; */
    align-self: stretch;
    width: 328px;
    height: 328px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  div.ret-courseDiv {
    width: 90%;
    height: 20px;
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-top: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: #eee;
  }

  .ret-courseDiv > p {
    color: var(--Neutral-500, var(--Surface-Shade, #8a8a8a));
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
  }
}
