div.courseForm {
  display: flex;
  flex-direction: row;
  gap: 48px;
  margin-top: 26px;
  margin-bottom: 30px;
}

.state-buttons {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  width: 200px;
  gap: 0;
  margin-top: 16px;
}

.state-buttons > button {
  display: flex;
  width: 72px;
  padding: 6px 8px;
  justify-content: center;
  align-self: center;
  margin: auto;
  align-items: center;
  /* gap: 4px; */
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #f7f7f7;
  color: var(--Text-Secondary, #7a7a7a);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.state-buttons > .active {
  border-radius: 8px;
  background: #004a98;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
}

.courseForm > label {
  color: var(--Text-Primary, #121212);
  font-family: "Manrope", sans-serif;
  height: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  align-self: stretch;
}

div.courseFormLeft > input,
div.courseFormLeft > select,
div.courseFormRight > input {
  display: flex;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  width: 476px;
  height: 48px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
}

div.courseFormRight > textarea {
  display: flex;
  padding: 12px 8px 70px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
  overflow: hidden;
}

div.courseFormLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 492px;
  height: 552px;
  gap: 24px;
}

div.courseFormRight {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 492px;
  height: 462px;
}

button.addCourseCourseButton {
  display: flex;
  width: 492px;
  padding: 12px 16px;
  justify-content: center;
  align-self: center;
  margin: auto;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #004a98;
  color: var(--Background, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
