section.itilcontainer {
  display: flex;
  margin-top: 75px;
  padding: 0px 150px;
  flex-direction: column;
  align-items: center;
}

.itilcerthusk {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.itilcertimages {
  display: flex;
  flex-direction: row;
  height: 468px;
}

.itilcertimages div {
  display: flex;
  width: 128.7px;
  padding: 0px 10px 181.18px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.01px;
  align-self: stretch;
}

.itilcertwrite {
  display: flex;
  padding: 10px;
  width: 480px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
}

.itilcertwrite h1 {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  align-items: flex-start;
  align-self: stretch;
  color: #0c0c0c;
  font-family: "Manrope", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
}

.itilcertwrite p {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  align-self: stretch;
}

.itilcertwrite p > span {
  color: #0c0c0c;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.itilcertwrite button {
  margin-top: 20px;
  display: flex;
  padding: 15px;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
  border: none;
  border-radius: 2px;
  background: #eb460e;
}

.itilcertwrite button > span {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

hr {
  display: flex;
  margin-top: 80px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid #f2b287;
}



@media only screen and (max-width: 600px) { 

  /* Mobile View CSS */
section.itilcontainer {
  margin-top: 30px; /* Adjusted margin-top for mobile */
  padding: 0 40px; /* Adjusted padding for mobile */
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
}

.itilcerthusk {
  display: flex;
  flex-direction: column; /* Changed to column layout for mobile */
  justify-content: center; /* Centered items for mobile */
  align-items: center; /* Centered items for mobile */
  align-self: stretch;
}

.itilcertimages {
  display: none;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  justify-content: center; /* Center items horizontally for mobile */
  gap: 20px; /* Adjusted gap between items for mobile */
  max-width: 100% !important;
}

.itilcertimages div {
  width: 100%; /* Adjusted width for mobile */
  padding: 10px 100px; /* Adjusted padding for mobile */
  max-width: 100%;
}

.itilcertwrite {
  display: flex;
  padding: 10px;
  width: 100%; /* Adjusted width for mobile */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; /* Centered items for mobile */
  align-self: stretch;
}

.itilcertwrite h1 {
  color: #0c0c0c;
  font-family: "Manrope", sans-serif;
  font-size: 24px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* Adjusted line height for mobile */
  text-align: center; /* Centered text for mobile */
  margin-bottom: 20px; /* Adjusted margin for mobile */
}

.itilcertwrite p {
  color: #0c0c0c;
  gap: 20px;
  width: 100%; /* Adjusted width for mobile */
  font-family: "Manrope", sans-serif;
  font-size: 16px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* Adjusted line height for mobile */
  text-align: center; /* Centered text for mobile */
}

.itilcertwrite button {
  margin-top: 20px;
  padding: 15px;
  gap: 10px;
  justify-content: center; /* Centered button text for mobile */
  align-items: center;
  border: none;
  border-radius: 2px;
  background: #eb460e;
  width: 100%; /* Adjusted width for mobile */
}

.itilcertwrite button > span {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 17px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 600;
  line-height: 17px; /* Adjusted line height for mobile */
  text-transform: uppercase;
}

hr {
  margin-top: 40px; /* Adjusted margin-top for mobile */
  border: 1px solid #f2b287;
}

 }
