section.ciscoHusk {
  display: flex;
  margin-top: 75px;
  padding: 0px 150px;
  flex-direction: column;
  align-items: center;
}

.ciscoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.ciscowriteup {
  display: flex;
  width: 545.22px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.ciscowriteup h1 {
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #0c0c0c;
  font-family: "Manrope";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
}

.ciscowriteup p {
  display: flex;
  padding-bottom: 40px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #0c0c0c;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.ciscowriteup h2 {
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #ff8142;
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.ciscowriteup ul {
  display: flex;
  flex-direction: column;
}

.ciscowriteup ul > li {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding-bottom: 7.5px;
  align-items: flex-start;
  align-self: stretch;
}

.ciscowriteup ul > li > img {
  width: 70px;
  height: 70px;
}

.ciscowriteup ul > li > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.ciscowriteup ul > li > div h1 {
  color: #414247;
  width: 452px;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}

.ciscowriteup ul > li > div p {
  display: flex;
  padding: 0px 20.53px 0.5px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #727272;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.ciscowriteup button {
  margin-top: 20px;
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  border: none;
  border-radius: 2px;
  background: #eb460e;
}

.ciscowriteup button > img {
  width: 17px;
  height: 17px;
}

.ciscoContainer img {
  display: flex;
  width: 124.7px;
  justify-content: flex-start;
  align-items: center;
}

hr {
  display: flex;
  margin-top: 83.4px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid #f2b287;
}



@media only screen and (max-width: 600px) {  

/* Mobile View CSS */
section.ciscoHusk {
  margin-top: 30px; /* Adjusted margin-top for mobile */
  padding: 0 20px; /* Adjusted padding for mobile */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ciscoContainer {
  display: flex;
  flex-direction: column; /* Changed to column layout for mobile */
  justify-content: center; /* Centered items vertically for mobile */
  align-items: center; /* Centered items horizontally for mobile */
}

.ciscowriteup {
  width: 100%; /* Adjusted width for mobile */
  padding: 10px; /* Adjusted padding for mobile */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centered items horizontally for mobile */
}

.ciscowriteup h1 {
  color: #0c0c0c;
  font-family: "Manrope", sans-serif;
  font-size: 24px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* Adjusted line height for mobile */
  text-align: center; /* Centered text for mobile */
  margin-bottom: 20px; /* Adjusted margin bottom for mobile */
}

.ciscowriteup p {
  color: #0c0c0c;
  font-family: "Manrope", sans-serif;
  font-size: 14px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* Adjusted line height for mobile */
  text-align: center; /* Centered text for mobile */
  padding-bottom: 20px; /* Adjusted padding bottom for mobile */
}

.ciscowriteup h2 {
  color: #ff8142;
  font-family: "Manrope", sans-serif;
  font-size: 18px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* Adjusted line height for mobile */
  text-align: center; /* Centered text for mobile */
  padding-bottom: 20px; /* Adjusted padding bottom for mobile */
}

.ciscowriteup ul > li {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjusted gap between elements for mobile */
}

.ciscowriteup ul > li > img {
  width: 70px;
  height: 70px;
}

.ciscowriteup ul > li > div h1 {
  width: 100%; /* Adjusted width for mobile */
  max-width: 250px; /* Added max-width for mobile */
  text-align: center; /* Centered text for mobile */
}

.ciscowriteup ul > li > div p {
  width: 100%; /* Adjusted width for mobile */
  max-width: 250px; /* Added max-width for mobile */
  text-align: center; /* Centered text for mobile */
}

.ciscowriteup button {
  margin-top: 20px; /* Adjusted margin top for mobile */
  padding: 15px;
  gap: 3px;
  display: flex;
  justify-content: center; /* Centered button text for mobile */
  align-items: center;
  border: none;
  border-radius: 2px;
  background-color: #eb460e;
}

.ciscowriteup button > img {
  width: 17px;
  height: 17px;
}

.ciscoContainer img {
  width: 100%; /* Adjusted width for mobile */
  max-width: 150px; /* Added max-width for mobile */
  align-self: center; /* Centered image for mobile */
}

hr {
  margin-top: 40px; /* Adjusted margin-top for mobile */
  border: 1px solid #f2b287;
}


}