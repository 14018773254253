section.admin-Husk {
  display: flex;
  height: 100vh;
  margin: auto;
  justify-content: center;
  align-items: center;
  background: var(--Surface, #f7f7f7);
}

div.flaskContainer {
  display: flex;
  height: 330px;
  width: 492px;
  padding: 50px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  /* flex: 1 0 0; */
  border-radius: 16px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
  box-shadow: 0px 45px 250px 0px rgba(17, 33, 39, 0.1),
    0px -4px 150px 0px rgba(35, 56, 65, 0.05);
}

div.flaskContainer > h1 {
  color: var(--Text-Primary, #121212);
  font-family: "Manrope", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

div.formEnviron {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

div.adminUsername {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.adminUsername > label {
  color: var(--Text-Primary, #121212);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  align-self: stretch;
}

div.adminUsername > input {
  display: flex;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
}

/* div.adminUsername > input:active,
input:focus {
  border-color: blue;
} */

div.adminUserToken {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.adminUserToken > label {
  color: var(--Text-Primary, #121212);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  align-self: stretch;
}

div.adminUserToken > input {
  display: flex;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
}

/* div.adminUserToken > input:active,
input:focus {
  border-color: blue;
} */

div.buttondiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.buttondiv > button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #004a98;
  color: var(--Background, #fff);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
