.cpTopContainer {
  margin-top: 26px;
}

.cpTopHomeButton {
  border: none;
  background: none;
  margin-bottom: 40px;
}

.cpHomeImgContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.cpHomeImgLeft {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 38px;
}

.cpHomeinnerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.cpHomeinnerLeft > label {
  align-self: stretch;
  color: var(--Text-Primary, #121212);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.cpHomeinnerLeft > input {
  display: flex;
  width: 442px;
  height: 28px;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
}

.cpHomeImgRight {
  width: 558px;
  height: 466px;
  border-radius: 16px;
  overflow: hidden;
  background: var(--Surface, #f7f7f7);
}

.cpHomeImgRight > img {
  width: auto;
  height: auto;
}

.cpAddHomeBannerButt {
  display: flex;
  width: 462px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #dd3b00;
  color: var(--Background, #fff);
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
