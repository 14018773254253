section.mazurehusk {
  display: flex;
  padding: 0px 150px;
  margin-top: 60px;
  flex-direction: column;
  align-items: center;
}

.mazurecontainer {
  display: flex;
  flex-direction: row;
  gap: 35px;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.firstmrow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.firstmrow > div {
  display: flex;
  width: 93.7px;
  padding: 10px 10px 341.18px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.01px;
  align-self: stretch;
}

.secondmrow {
  display: flex;
  width: 515.22px;
  padding: 10px;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.secondmrow h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #0c0c0c;
  font-family: "Manrope", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
}

.secondmrow p {
  display: flex;
  width: 100%;
  padding-bottom: 20.5px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.secondmrow p > span {
  color: #0c0c0c;
  width: 488px;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.mazurelist {
  display: flex;
  padding: 0 0 20px 22px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.mazurelist ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 0.5px;
}

.secondmrow button {
  display: flex;
  padding: 15px;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  border: none;
  border-radius: 2px;
  background: #eb460e;
}

.secondmrow button > span {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

hr {
  display: flex;
  margin-top: 64px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid #f2b287;
}



@media only screen and (max-width: 600px) { 

  /* Mobile View CSS */
section.mazurehusk {
  padding: 0 20px; /* Adjusted padding for mobile */
  margin-top: 30px; /* Adjusted margin-top for mobile */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mazurecontainer {
  display: flex;
  flex-direction: column; /* Changed to column layout for mobile */
  gap: 20px; /* Adjusted gap for mobile */
  justify-content: center; /* Centered items for mobile */
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.firstmrow > div {
  width: 100%; /* Adjusted width for mobile */
  padding: 10px; /* Adjusted padding for mobile */
  max-width: 40.7px; /* Added max-width for mobile */
  display: none;
}




.secondmrow {
  width: 100%; /* Adjusted width for mobile */
  padding: 10px; /* Adjusted padding for mobile */
}

.secondmrow h1 {
  color: #0c0c0c;
  font-family: "Manrope", sans-serif;
  font-size: 24px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* Adjusted line height for mobile */
  text-align: center; /* Centered text for mobile */
}

.secondmrow p > span {
  color: #0c0c0c;
  max-width: 100%; /* Adjusted max-width for mobile */
  font-family: "Manrope", sans-serif;
  font-size: 16px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* Adjusted line height for mobile */
}

.mazurelist {
  display: flex;
  padding: 0 0 20px; /* Adjusted padding for mobile */
  flex-direction: column;
  align-items: center; /* Centered items for mobile */
  align-self: stretch;
}

.mazurelist ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 0.5px;
}

.secondmrow button {
  padding: 15px;
  gap: 10px; /* Adjusted gap for mobile */
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  background: #eb460e;
  width: 100%; /* Adjusted width for mobile */
}

.secondmrow button > span {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 16px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* Adjusted line height for mobile */
  text-align: center; /* Centered text for mobile */
}

hr {
  margin-top: 40px; /* Adjusted margin-top for mobile */
  border: 1px solid #f2b287;
}


}