div.ret-shell {
  display: flex;
  /* width: var(--ViewPortWidth, 1440px); */
  margin: auto;
  padding: 0px 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background: #fff;
}

div.ret-innershell {
  display: flex;
  max-width: 1240px;
  width: 100%;
  padding: 40px 0px;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}

div.ret-shell1 {
  display: flex;
  width: 360px;
  height: 596px;
  padding: 100px 124px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #f4f4f5;
}

div.ret-shell1top {
  display: flex;
  width: 360px;
  height: 100px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.ret-shell1top > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.ret-shell1top > h1 > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.36px;
}

p.ret-shell1topwords {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

div.ret-shellbottom {
  display: flex;
  width: 360px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}

.ret-shellform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

form.shellform span.name,
form.shellform span.mail,
form.shellform span.phone,
form.shellform span.messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

span > label {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

span > input {
  display: flex;
  padding: 10px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Surface, #f7f7f7);
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

span > textarea {
  display: flex;
  height: 120px;
  padding: 10px 16px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Surface, #f7f7f7);
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

.ret-shellbottom > button {
  display: flex;
  width: 360px;
  height: 40px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-items: stretch;
  border: none;
  border-radius: 8px;
  background: #dd3b00;
}

button > span {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

div.ret-shell2 {
  display: flex;
  padding: 80px 124px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.ret-shell2 > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.ret-shell2 > h1 > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.36px;
}

div.ret-shell2contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

div.ret-shell2top {
  display: flex;
  width: 360px;
  height: 128px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
}

.ret-shell2top > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.ret-shell2top > h1 > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 220% */
  letter-spacing: -0.2px;
}

div.ret-shell2top2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.ret-shell2top2 > h2 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

div.ret-shell2top2 > h5 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div.ret-shell2top2 > h6 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

div.ret-shell2mid {
  display: flex;
  width: 360px;
  height: 128px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
}

.ret-shell2mid > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.ret-shell2mid > h1 > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 220% */
  letter-spacing: -0.2px;
}

div.ret-shell2mid2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.ret-shell2mid2 > h2 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

div.ret-shell2mid2 > h5 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div.ret-shell2mid2 > h6 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

div.ret-shell2end {
  display: flex;
  width: 360px;
  height: 128px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
}

.ret-shell2end > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.ret-shell2end > h1 > span {
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 220% */
  letter-spacing: -0.2px;
}

div.ret-shell2end2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

div.ret-shell2end2 > h2 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

div.ret-shell2end2 > h5 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  align-self: stretch;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div.ret-shell2end2 > h6 {
  color: var(--Neutral-700, #505050);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

@media only screen and (max-width: 600px) {
  div.ret-shell {
    display: flex;
    /* width: var(--ViewPortWidth, 1440px); */
    margin: auto;
    padding: 0px 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    background: #fff;
  }

  div.ret-innershell {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    padding: 20px 0px;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  div.ret-shell1 {
    display: flex;
    width: 100%;
    height: 596px;
    /* padding: 100px 124px; */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    border-radius: 8px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: #f4f4f5;
  }

  div.ret-shell1top {
    display: flex;
    width: 360px;
    height: 100px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .ret-shell1top > h1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .ret-shell1top > h1 > span {
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    font-family: "Manrope", sans-serif;
    align-self: stretch;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.36px;
  }

  p.ret-shell1topwords {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 70%;
    margin-bottom: 10px;
  }

  div.ret-shellbottom {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
  }

  .ret-shellform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  form.shellform span.name,
  form.shellform span.mail,
  form.shellform span.phone,
  form.shellform span.messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  span > label {
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }

  form.shellform > span > input {
    display: flex;
    width: 60%;
    padding: 10px 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: var(--Surface, #f7f7f7);
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
  }

  span > textarea {
    display: flex;
    height: 120px;
    width: 80%;
    padding: 10px 16px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: var(--Surface, #f7f7f7);
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
  }

  .ret-shellbottom > button {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-items: stretch;
    border: none;
    border-radius: 8px;
    background: #dd3b00;
  }

  button > span {
    color: #fff;
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  div.ret-shell2 {
    display: flex;
    padding: 40px 62px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .ret-shell2 > h1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .ret-shell2 > h1 > span {
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    font-family: "Manrope", sans-serif;
    align-self: stretch;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.36px;
  }

  div.ret-shell2contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }

  div.ret-shell2top {
    display: flex;
    width: 100%;
    height: auto;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  }

  .ret-shell2top > h1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .ret-shell2top > h1 > span {
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px; /* 220% */
    letter-spacing: -0.2px;
  }

  div.ret-shell2top2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  div.ret-shell2top2 > h2 {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  div.ret-shell2top2 > h5 {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    align-self: stretch;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  div.ret-shell2top2 > h6 {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  div.ret-shell2mid {
    display: flex;
    width: 100%;
    height: auto;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  }

  .ret-shell2mid > h1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .ret-shell2mid > h1 > span {
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px; /* 220% */
    letter-spacing: -0.2px;
  }

  div.ret-shell2mid2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  div.ret-shell2mid2 > h2 {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  div.ret-shell2mid2 > h5 {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    align-self: stretch;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  div.ret-shell2mid2 > h6 {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  div.ret-shell2end {
    display: flex;
    width: 100%;
    height: auto;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  }

  .ret-shell2end > h1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .ret-shell2end > h1 > span {
    color: var(--Neutral-900, var(--Text-Primary, #121212));
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px; /* 220% */
    letter-spacing: -0.2px;
  }

  div.ret-shell2end2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }

  div.ret-shell2end2 > h2 {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  div.ret-shell2end2 > h5 {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    align-self: stretch;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  div.ret-shell2end2 > h6 {
    color: var(--Neutral-700, #505050);
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
