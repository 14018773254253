.editcorpbannercontainer {
  margin-top: 26px;
}

.editcorpbannerhusk {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.editcorpbannerform {
  width: 452px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.editcorpbannerform div {
  display: flex;
  width: 492px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.editcorpbannerform div > label {
  color: var(--Text-Primary, #121212);
  width: 452px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  align-self: stretch;
}

.editcorpbannerform div > input {
  width: 452px;
  height: 48px;
  display: flex;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
}

.editcorpbannerform button {
  display: flex;
  width: 468px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #eb460e;
  color: var(--Background, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.editcorpbannerright {
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 558px;
  height: 466px;
  flex-shrink: 0;
  border-radius: 16px;
}

.editcorpbannerright img {
  width: 558px;
  height: 466px;
  flex-shrink: 0;
  border-radius: 16px;
}

.editcorpbannerright button {
  display: flex;
  width: 492px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #ffe5e5;
  color: #f00;
  flex: 1 0 0;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
