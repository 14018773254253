.banner {
  position: relative;
  display: flex;
  width: 100%;
  height: 85vh;
  /* padding: 0px 16px; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
}

.banner > .bannerImg {
  position: absolute;
  filter: blur(0.5px);
  height: calc(120svh - 100px);
  z-index: -2;
  opacity: 0.4;
  width: 100%;
  object-fit: cover;
}

div.bannerContent {
  display: flex;
  width: 100%;
  height: 235px;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

p.bannerContentP {
  align-self: stretch;
  color: var(--Text-Secondary, #121212);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

h1.bannerContenth1 {
  align-self: stretch;
  color: var(--Text-Primary, #121212);
  font-family: Manrope;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 67.2px; /* 120% */
}

button.bannerButtontop {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none;
  background: #dd3b00;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.carouselNav {
  display: flex;
  gap: 2em;
}

.carouselNav button {
  background: none;
  border: none;
  cursor: pointer;
}

.banBut {
  color: #ffffff;
}

.carouselBut {
  position: absolute;
  bottom: 3em;
  right: 3em;
  padding: 1.2rem 1.5rem;
  background-color: #004a98;
  color: white;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  border-radius: 1.5rem;
  font-size: 0.875rem;
  display: flex;
  gap: 3em;
}

.carouselButTxt {
  color: white;
}



@media only screen and (max-width: 600px) {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: auto; /* Adjust height to fit content */
  }
  
  .banner > .bannerImg {
    filter: blur(0.5px);
    opacity: 0.4;
    width: 100%;
    max-width: 100%; /* Ensure image fits within container */
    object-fit: cover;
  }
  
  div.bannerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    text-align: center; /* Center-align text */
    max-width: 80%; /* Set max-width for text content */
    margin: auto; /* Center content horizontally */
  }
  
  p.bannerContentP {
    color: var(--Text-Secondary, #121212);
    font-family: Manrope;
    font-size: 14px; /* Adjust font size for mobile */
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
  }
  
  h1.bannerContenth1 {
    color: var(--Text-Primary, #121212);
    font-family: Manrope;
    font-size: 40px !important; /* Adjust font size for mobile */
    font-weight: 900 !important;
    line-height: 1.2 !important;
    text-align: left !important;
    max-width: 100% !important;
  }
  
  button.bannerButtontop {
    padding: auto !important; /* Adjust padding for mobile */
    border-radius: 8px;
    background: #00d6dd;
    color: #fff;
    font-size: 14px; /* Adjust font size for mobile */
    font-weight: 600;
    line-height: normal;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
max-width: 100% !important;
width: 100% !important;
margin-bottom: 80px !important;
  }
  
  .carouselNav {
    display: flex;
    gap: 1em; /* Adjust gap for mobile */
  }
  
  .carouselBut {
    bottom: 1rem;
    right: 1rem;
    padding: 10px 15px; /* Adjust padding for mobile */
    font-size: 14px; /* Adjust font size for mobile */
    gap: 2em; /* Adjust gap for mobile */
    margin-left: 1em;

  }
  
}