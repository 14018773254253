div.adminSidebar {
  display: flex;
  max-width: 240px;
  padding-top: 26px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 26px;
  padding-bottom: 664px;
  border-right: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
}

div.sidebar2 {
  display: flex;
  width: 240px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

button.sidebarButton {
  display: flex;
  padding: 16px 32px;
  width: 182px;
  height: 54px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: none;
  background: #004a98;
}

button.sidebarButton > span {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 550;
  line-height: 20px;
  letter-spacing: 0.32px;
}

div.divDash {
  display: flex;
  width: 240px;
  height: 56px;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

div.divDash > img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

div.divDash > span {
  color: var(--Surface-Shade, #8a8a8a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.32px;
}

div.divCourse {
  display: flex;
  width: 240px;
  height: 56px;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

div.divCourse > img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: #8a8a8a;
}

div.divCourse > span {
  color: var(--Surface-Shade, #8a8a8a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.32px;
}

div.divData {
  display: flex;
  width: 240px;
  height: 56px;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

div.divData > img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

div.divData > span {
  color: var(--Surface-Shade, #8a8a8a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.32px;
}

.sidebar2 div.active {
  background: #f2f6fa;
  border-right: 2px solid #dd3b00;
  border-radius: 0;
  display: flex;
  width: 182px;
  height: 56px;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}

.sidebar2 div.active > img {
  /* position: absolute;
  color: #121212;
  top: 18px;
  left: 32px;
  width: 24px;
  height: 24px;
  flex-shrink: 0; */
}

.sidebar2 div.active > span {
  color: var(--Text-Primary, #121212);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.32px;
}
