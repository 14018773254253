section.cTitleContainer {
  padding: 0px 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cTitleHusk {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}
.cTitleTop {
  display: flex;
  width: 1060px;
  padding: 5px 5px 5px 0px;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: 10px;
}

.cTitleTop > img {
  width: 50px;
  height: 3px;
}
.cTitleTop > h1 {
  color: #eb460e;
  font-family: "Manrope", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.horizontalLine {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid #f2b287;
}

@media screen and (max-width: 600px) {
  section.cTitleContainer {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cTitleHusk {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
  }
  .cTitleTop {
    display: flex;
    width: 600px;
    padding: 5px 5px 5px 0px;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    gap: 10px;
  }

  .cTitleTop > img {
    width: 50px;
    height: 3px;
  }
  .cTitleTop > h1 {
    color: #eb460e;
    font-family: "Manrope", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  .horizontalLine {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border: 1px solid #f2b287;
  }
}
