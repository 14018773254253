.retailCourses {
  display: flex;
  padding: 68px 100px 65px 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
}

.retailCourses > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 550;
  line-height: 44px;
  letter-spacing: -0.36px;
}

.coursesforret {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

.coursedivisesret {
  display: flex;
  width: 262px;
  height: 223px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Neutral-100, #eee);
}

.coursedivisesret > img {
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  width: 260px;
  height: 138px;
  object-fit: cover;
}

.coursedivisesret > p {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #eee;
  color: var(--Neutral-500, var(--Surface-Shade, #6d6d6d));
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
