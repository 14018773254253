.CorpCourses {
  display: flex;
  padding: 68px 100px 65px 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
}

.CorpCourses > h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  color: var(--Neutral-900, var(--Text-Primary, #121212));
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 550;
  line-height: 44px;
  letter-spacing: -0.36px;
}

.coursesforcorp {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

.coursedivises {
  display: flex;
  width: 262px;
  height: 223px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Neutral-100, #eee);
}

.coursedivises > img {
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  width: 260px;
  height: 138px;
  object-fit: cover;
}

.coursedivises > p {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: #eee;
  color: var(--Neutral-500, var(--Surface-Shade, #6d6d6d));
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}




@media only screen and (max-width: 600px) {
  .CorpCourses {
    padding: 20px; /* Adjust padding for smaller screens */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Adjust the gap between elements for smaller screens */
  }
  
  .CorpCourses > h1 {
    text-align: center;
    font-size: 24px; /* Decrease font size for smaller screens */
    line-height: 30px; /* Adjust line height for smaller screens */
    margin: 0; /* Remove margin to prevent extra space */
  }
  
  .coursesforcorp {
    display: flex;
    flex-wrap: wrap; /* Allow courses to wrap on smaller screens */
    justify-content: center; /* Center courses horizontally */
    gap: 20px; /* Adjust the gap between courses */
  }
  
  .coursedivises {
    width: calc(50% - 20px); /* Adjust width for two-column layout on smaller screens */
    max-width: 300px; /* Limit maximum width of course divs */
    height: auto; /* Allow height to adjust based on content */
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: var(--Neutral-100, #eee);
  }
  
  .coursedivises > img {
    width: 100%;
    height: auto; /* Maintain aspect ratio of images */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .coursedivises > p {
    padding: 10px; /* Adjust padding for smaller screens */
    margin: 0; /* Remove margin to prevent extra space */
    border-top: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
    background: #eee;
    color: var(--Neutral-500, var(--Surface-Shade, #6d6d6d));
    font-size: 12px; /* Decrease font size for smaller screens */
    line-height: 16px; /* Adjust line height for smaller screens */
  }
  
}