.hpsetheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1045px;
  margin-top: 26px;
  align-items: center;
}

.hpsetheader > h1 {
  color: var(--Text-Primary, #121212);
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.4px;
}

.addnewhomeimagebutton {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: #dd3b00;
  color: #fff;
  width: 204px;
  height: 54px;
  border: none;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.32px;
}

.hpsetbody {
  margin-top: 40px;
  display: flex;
  width: 1045px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 40px;
  flex-wrap: wrap;
}

.bannerContentItem {
  display: flex;
  min-width: 266px;
  max-width: 266px;
  height: 221px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 11px;
  flex: 1 0 0;
}

.bannerContentItem > img {
  display: flex;
  height: 144px;
  width: 100%;
  /* padding: 48px 60px; */
  object-fit: cover;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
}

.bannerContentItem > p {
  color: var(--Surface-Shade, #8a8a8a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.32px;
  align-self: stretch;
  overflow: hidden;
}
