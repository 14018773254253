.banner {
  position: relative;
  display: flex;
  width: 100%;
  height: 85vh;
  /* padding: 0px 16px; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
}

.banner > .bannerImg {
  position: absolute;
  filter: blur(0.5px);
  height: calc(120svh - 100px);
  z-index: -2;
  opacity: 0.4;
  width: 100%;
  object-fit: cover;
}

div.bannerContent {
  display: flex;
  width: 1208px;
  height: 235px;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

p.bannerContentP {
  align-self: stretch;
  color: var(--Text-Secondary, #121212);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

h1.bannerContenth1 {
  align-self: stretch;
  color: var(--Text-Primary, #121212);
  font-family: Manrope;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 67.2px; /* 120% */
}

button.bannerButtontop {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none;
  background: #dd3b00;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.carouselNav {
  display: flex;
  gap: 2em;
}

.carouselNav button {
  background: none;
  border: none;
  cursor: pointer;
}

.banBut {
  color: #ffffff;
}

.carouselBut {
  position: absolute;
  bottom: 3em;
  right: 3em;
  padding: 1.2rem 1.5rem;
  background-color: #004a98;
  color: white;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  border-radius: 1.5rem;
  font-size: 0.875rem;
  display: flex;
  gap: 3em;
}


.carouselButTxt {
  color: white;
}