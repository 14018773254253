* {
  font-family: "Manrope", sans-serif;
}

section#contentwrap {
  /* width: var(--viewPorthWidth, 1440px); */
  height: 1238px;
  position: relative;
  z-index: 0;
  background: url("../../assets/images/contactbg.jpeg");
  background-position: center/center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

section#contentwrap::before {
  height: 1285px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: #004a98;
  opacity: 0.85;
}

div#contents {
  z-index: 3;
  display: flex;
  width: 1000px;
  max-width: 1000px;
  align-items: flex-start;
  flex: 1 0 0;
}

div#part1 {
  display: flex;
  padding-right: 6.69px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

div#contactdetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 523.31px;
  min-height: 1px;
  padding-right: 100px;
  align-items: flex-start;
  flex: 1 0 0;
}

div#contactdetails > h1 {
  display: flex;
  padding-right: 183.31px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

div#contactdetails > h1 > span {
  color: #fff;
  font-size: 54.4px;
  font-style: normal;
  font-weight: 700;
  line-height: 65.28px;
  max-width: 493.31px;
  max-height: 65.28px;
}

div#contactdetails > ul {
  display: flex;
  padding: 20px 200px 20px 0px;
  gap: 7.5px;
  flex-direction: column;
  list-style: none;
  align-items: flex-start;
  align-self: stretch;
}

div#contactdetails > ul > li {
  display: flex;
  padding: 0px 17.09px 7.5px 0px;
  align-items: center;
  align-self: stretch;
}

div#contactdetails > ul > li > img {
  display: flex;
  width: 22.5px;
  padding-right: 6.5px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

div#contactdetails > ul > li > p {
  display: flex;
  padding: 0px 43.72px 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  color: #f5f5f5;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

div#enquiryQuestions {
  display: flex;
  flex-direction: column;
  padding-bottom: 41px;
  /* justify-content: center; */
  align-items: flex-start;
  align-self: stretch;
}

div#enquiryQuestions > p {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

div#locateUs {
  display: flex;
  padding: 30px 0px 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

h3.locateHeading {
  display: flex;
  max-width: 478.645px;
  width: 100%;
  gap: 9px;
  padding-right: 9px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

h3.locateHeading > p {
  color: #fff;
  font-family: Arial;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.contactbreakline {
  /* flex: 1; */
  height: 1px;
  width: 80%;
  background-color: #898989;
  border: none;
}

div.locateMap {
  display: flex;
  padding: 0px 0.31px 20px 0px;
  width: 493px;
  height: 180px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

div.followUs {
  display: flex;
  padding: 30px 0px 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

h3.followHeading {
  display: flex;
  max-width: 478.645px;
  width: 100%;
  gap: 9px;
  padding-right: 9px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

h3#followHeading > p {
  color: #fff;
  font-family: Arial;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

h3.followHeading > p {
  color: #fff;
  font-family: Arial;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.contactbreakline {
  /* flex: 1; */
  height: 1px;
  width: 100%;
  background-color: #898989;
  border: none;
}

div.social-media-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 493px;
  height: 45px;
}

ul.sm-inner-list {
  display: flex;
  padding-right: 238.31px;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
}

ul.sm-inner-list > li {
  display: flex;
  padding: 15px;
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #fff;
}

ul.sm-inner-list > li > FaTwitter,
FaFacebook,
FaInstagram,
FaLinkedIn {
  display: flex;
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
}

div.part2 {
  display: flex;
  width: 397px;
  height: 738px;
  padding-left: 3px;
  align-items: flex-start;
  border-left: 3px solid rgba(255, 255, 255, 0.47);
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 10px 20px 50px rgba(0, 0, 0, 0.07);
}

div.contactFormContainer {
  display: flex;
  padding: 75.13px 35px 110.14px 35px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.contactFormContainer > .cformHeader {
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.cformHeader > h2 {
  display: flex;
  padding: 0px 88.156px 0px 89.844px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

h2 > span {
  color: #fff;
  max-width: 327px;
  width: 149px;
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 100% */
}

form.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 15px;
  width: 327px;
  height: 525px;
}

.contact-form > input {
  display: flex;
  max-width: 327px;
  min-height: 40px;
  padding: 7px 13px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 2.5px;
  border: 1px solid #ced4da;
  background: #fff;
}

.contact-form > label {
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.contact-form > textarea {
  width: 325px;
  height: 148px;
  border-radius: 2.5px;
  border: 1px solid #ced4da;
  background: #fff;
}

button.contactButton {
  display: flex;
  padding: 7px 30.578px 7px 30.952px;
  width: 250.53px;
  height: 38px;
  justify-content: center;
  align-self: center;
  align-items: center;
  border-radius: 4px;
  background: #b50d0d;
  border: none;
  cursor: pointer;
}

.contactButton:hover {
  opacity: 0.7;
  transition: opacity 0.25s ease-in-out;
}

button.contactButton > span {
  color: #fff;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.contact-social {
  color: black;
}

@media only screen and (max-width: 600px) {
  * {
    font-family: "Manrope", sans-serif;
  }

  section#contentwrap {
    height: auto; /* Adjusted height for mobile */
    display: flex;
    /* width: 100%; */
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  section#contentwrap::before {
    height: 1586.27px; /* Adjusted height for mobile */
  }

  div#contents {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div#part1 {
    display: flex;
    flex-direction: column; /* Change to column layout for mobile */
    padding-left: 20px;
    margin: 0;
  }

  div#contactdetails {
    width: 100%; /* Adjusted width for mobile */
    padding: 10px 0px; /* Adjusted padding for mobile */
  }

  div#contactdetails > h1 {
    width: 380px;
    height: 65.27px;
  }

  div#contactdetails > ul {
    padding: 20px 0; /* Adjusted padding for mobile */
  }

  div#contactdetails > ul > li {
    padding: 7.5px 0; /* Adjusted padding for mobile */
  }

  div#contactdetails > ul > li > img {
    padding-right: 10px; /* Adjusted padding for mobile */
  }

  div#locateUs {
    display: flex;
    padding: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
  }

  h3.locateHeading {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }

  .locateHeading p {
    width: 400px;
    color: #fff;
    font-family: Arial;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
  }

  .locateHeading .contactbreakline {
    width: 1%;
    flex: 0;
  }

  h3.followHeading {
    display: flex;
    width: 100%;
    gap: 9px;
    padding-right: 9px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  div.part2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-left: none;
    box-shadow: none;
  }

  div.contactFormContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
  }

  .contactFormContainer .cformHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
  }

  .cformHeader > h2 {
    display: flex;
    /* padding: 0px 88.156px 0px 89.844px; */
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    margin-bottom: 10px;
  }

  h2 > span {
    color: #fff;
    width: 149px;
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 100% */
  }

  form.contact-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 15px;
    width: 80%;
  }

  .contact-form > input {
    display: flex;
    min-height: 20px;
    width: 100%;
    padding: 7px 13px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 2.5px;
    border: 1px solid #ced4da;
    background: #fff;
  }

  .contact-form > textarea {
    width: 110%;
    height: 80px;
    border-radius: 2.5px;
    border: 1px solid #ced4da;
    background: #fff;
  }

  .social-media-list {
    margin-bottom: 2em;
  }

  div.locateMap {
    display: flex;
    width: 90%;
    height: 180px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  iframe {
    width: 100%;
  }

  button.contactButton {
    display: flex;
    padding: 7px 30.578px 7px 30.952px;
    width: 250.53px;
    height: 38px;
    justify-content: center;
    align-self: center;
    align-items: center;
    border-radius: 4px;
    background: #b50d0d;
    border: none;
    cursor: pointer;
  }

  .followUs {
    max-width: 50%;
    width: 50%;
  }
}
