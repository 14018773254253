.tabs {
  display: flex;
  height: 600px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  margin-top: 60px;
  position: relative;
}
.carousel {
  width: 100%;
  display: flex;
  padding: 8px 0px 8px 16px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.carousel-item {
  display: none;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.carousel-item.active {
  display: flex;
  flex-direction: row;
  padding: 8px 24px;
  gap: 20px;
  align-items: flex-start;
  opacity: 1;
  background: none;
  border-radius: 0;
  justify-content: center;
}
.content-banner {
  max-width: 900px;
  width: 100%;
  overflow: hidden;
  height: 100%;
  animation: fillScreen 1s forwards, resizeImage 1s forwards 1s;
}
@keyframes fillScreen {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
@keyframes resizeImage {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
.content-banner img {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 400px;
}
.content-details {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
}
.content-text {
  max-width: 600px;
  width: 80%;
  margin-right: 50px;
  opacity: 0;
  animation: fadeInText 2s forwards 2s;
}
.text-animate p {
  opacity: 1;
  white-space: normal;
  overflow: visible;
  animation: typing 2.5s linear forwards;
}

@keyframes fadeInText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes typing {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
.content-text h2 {
  margin-bottom: 20px;
}
.content-text p {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  transition: clip-path 2.5s linear;
}
.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.carousel-control.prev {
  left: 10px;
}
.carousel-control.next {
  right: 10px;
}
@media (max-width: 768px) {
  .carousel-item {
    flex-direction: column;
  }
}
