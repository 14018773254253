section.pmicerthusk {
  display: flex;
  margin-top: 60px;
  padding: 0px 150px;
  flex-direction: column;
  align-items: center;
}

.pmicertcontainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-right: 157.38px;
  flex: 1 0 0;
  align-self: stretch;
}

.pmicertwrites {
  display: flex;
  width: 545.22px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.pmicertwrites h1 {
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #0c0c0c;
  font-family: "Manrope";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
}

.pmicertwrites p {
  display: flex;
  gap: 20px;
  padding-bottom: 40px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.pmicertwrites p > span {
  display: flex;
  padding-bottom: 0.5px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #0c0c0c;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.pmicertwrites button {
  display: flex;
  padding: 15px;
  gap: 3.5px;
  justify-content: center;
  align-items: flex-start;
  border: none;
  border-radius: 2px;
  background: #eb460e;
}

.pmiimgs {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-top: 10px;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
}

.pmiimgs img {
  width: 120px;
}

hr {
  display: flex;
  margin-top: 60px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid #f2b287;
}

@media screen and (max-width: 600px) {
  section.pmicerthusk {
    margin-top: 30px; /* Adjusted margin-top for mobile */
    padding: 0 20px; /* Adjusted padding for mobile */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pmicertcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Centered items horizontally for mobile */
    padding: 0; /* Removed right padding for mobile */
    flex: 1 0 0;
    align-self: stretch;
  }

  .pmicertwrites {
    width: 100%; /* Adjusted width for mobile */
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centered items horizontally for mobile */
    align-self: stretch;
  }

  .pmicertwrites h1 {
    color: #0c0c0c;
    font-family: "Manrope";
    font-size: 24px; /* Adjusted font size for mobile */
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* Adjusted line height for mobile */
    text-align: center; /* Centered text for mobile */
    padding-bottom: 20px;
  }

  .pmicertwrites p {
    gap: 20px;
    padding-bottom: 40px;
    text-align: center; /* Centered text for mobile */
  }

  .pmicertwrites p > span {
    color: #0c0c0c;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .pmicertwrites button {
    padding: 15px;
    gap: 3.5px;
    display: flex;
    justify-content: center; /* Centered button text for mobile */
    align-items: center;
    border: none;
    border-radius: 2px;
    background: #eb460e;
  }

  .pmiimgs {
    display: flex;
    flex-direction: row; /* Changed to column layout for mobile */
    padding: 10px 0; /* Adjusted padding for mobile */
    gap: 20px;
    justify-content: center; /* Centered items horizontally for mobile */
    align-items: center; /* Centered items horizontally for mobile */
    align-self: stretch;
  }

  .pmiimgs img {
    width: 100%; /* Adjusted width for mobile */
    max-width: 120px; /* Added max-width for mobile */
  }

  hr {
    margin-top: 40px; /* Adjusted margin-top for mobile */
    border: 1px solid #f2b287;
  }
}
