section.testpartner {
  display: flex;
  padding: 100px 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.testContainer {
  display: flex;
  margin: auto;
  flex-direction: column;
  padding: 10px;
  /* width: 1140px;
  max-width: 1140px; */
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.testPartHead {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.testPartHead > h1 {
  color: #eb460e;
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.testPartlogos {
  display: flex;
  padding-bottom: 30px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
}

.testlogospartners {
  display: flex;
  padding: 25px 0px;
  gap: 41px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.testlogospartners > img {
  display: flex;
  width: 239px;
  height: 150px;
  flex-shrink: 0;
  align-self: stretch;
}

@media only screen and (max-width: 600px) {
  section.testpartner {
    display: flex;
    max-width: 600px;
    height: auto;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
  }
  .testContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
  }

  .testPartHead {
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }

  .testPartHead > h1 {
    color: #eb460e;
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px;
  }

  .testPartlogos {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .testlogospartners {
    display: flex;
    flex-direction: row;
    width: 310px;
    justify-content: space-between;
    gap: 18px;
    flex-wrap: wrap;
  }

  .testlogospartners > img {
    display: flex;
    width: 100px;
    height: 80px;
    flex-shrink: 0;
    align-self: stretch;
  }
}
