.table-container {
  margin-top: 26px;
  width: 1045px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.regTabHead {
  height: 56px;
  margin: 0;
  padding: 0;
  background: #f2f6fa;
}

.tabhead1,
.tabhead3,
.tabhead4,
.tabhead5,
.tabhead6 {
  position: relative;
  width: 201px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  align-items: center;
  align-self: stretch;
}

.tabhead2 {
  position: relative;
  width: 232.5px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  align-items: center;
  align-self: stretch;
}

.tabhead1 span,
.tabhead2 span,
.tabhead3 span,
.tabhead4 span,
.tabhead5 span,
.tabhead6 span {
  position: absolute;
  left: 16px;
  top: 16px;
  color: var(--Text-Primary, #121212);
  max-width: 117px;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}

.sortIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
}

.tabbodyrow1,
.tabbodyrow2,
.tabbodyrow3,
.tabbodyrow4,
.tabbodyrow5 {
  width: 201px;
  height: 40px;
  padding: 8px 16px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  align-items: center;
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}

.tabbodyrow6 {
  width: 100px;
  height: 40px;
  padding: 8px 16px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  align-items: center;
  color: var(--Text-Secondary, #7a7a7a);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}
