div.adminNavbar {
  display: flex;
  /* width: 1285px; */
  height: 87px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
  background: var(--Background, #fff);
}

.adminNavbar2 {
  display: flex;
  flex: 1;
  gap: 80px;
  padding: 17px 32px;
  justify-content: center;
  align-items: flex-end;
  border-left: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
}

div.adminLogo {
  display: flex;
  width: 240px;
  padding: 30px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--Background, #fff);
}

.adminLogoImg {
  display: flex;
  width: 240px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

div.adminHeading {
  display: flex;
  width: 227px;
  height: 54px;
  flex-direction: column;
  align-items: flex-start;
}

h1.adminNavHead {
  color: var(--Text-Primary, #121212);
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.48px;
}

div.adminHeading > p {
  color: var(--Surface-Shade, #8a8a8a);
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.24px;
}

div.searchAndButton {
  display: flex;
  align-items: flex-start;
  height: 44px;
  gap: 8px;
}

input.adminSearchBar {
  display: flex;
  width: 595px;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  justify-content: space-between;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Border, rgba(0, 0, 0, 0.08));
}

button.adminNavSearchButton {
  display: flex;
  width: 83px;
  height: 40px;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 8px;
  background: #004a98;
}

button.adminNavSearchButton > span {
  color: var(--Background, #fff);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.32px;
}
