div.partShell {
  display: flex;
  /* width: var(--ViewPortWidth, 1440px); */
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #e2e4e6;
  overflow: hidden;
}

div.partnerContainer {
  display: flex;
  max-width: 1240px;
  width: 100%;
  margin: auto;
  padding: 37px 43px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

h1.partnerHead {
  display: flex;
  width: 1138px;
  height: 44px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

h1.partnerHead > span {
  color: #979ea6;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.24px;
}

div.partnersLogos {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  height: 48px;
  align-items: center;
  justify-content: center;
  gap: 38px;
  align-self: stretch;
  animation: scroll 20s linear infinite both 3s;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(50%);
  }
}

div.partnersLogos > img {
  height: 48px;
}
